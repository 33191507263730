import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd/lib';
import { useDevice } from '../../hook/useDevice';
import AnimationLine from '../animationLine';
import { Link } from "react-router-dom";
import axiosInstance from '../../until/axios';
import { message } from 'antd';
import parse from 'html-react-parser';

const New = () => {
    const { isMobile } = useDevice();

    const [news, setNews] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 10,
        pageSite: 2,
    });

    const fetchCareer = async () => {
        await axiosInstance
            .get(`/news/getAll`, { params: pagination })
            .then((res) => {
                const data = res?.data;
                setNews(data || []);
            })
            .catch(() => message.error("Error server!"));
    };

    useEffect(() => {
        fetchCareer();
    }, [pagination]);

    return (
        <div className='container mx-auto my-5'>
            <div className="pb-5">
                <h1 className={isMobile ? "text-center text-[1.5rem] font-bold text-[#42495B]" : "text-center text-[1.875rem] font-bold text-[#42495B] "}>Tin tức và tuyển dụng</h1>
            </div>
            <div className="pb-5">
                <AnimationLine />
            </div>
            <Row>
                <Col xs={24} xl={12} className={isMobile ? "mb-5" : "pr-5"}>
                    <div className={isMobile ? "mx-[30px]" : undefined}>
                        <Link to={"/tin-tuc/" + news?.data?.[0]?.news_slug }>
                            <img src={news.data?.[0]?.news_image} alt="movie" className="w-full  transition-opacity duration-300" />
                            <p className={isMobile ? "text-[#42495B] mt-3 text-xl font-bold" : "text-[#42495B] mt-3 text-2xl font-bold"}>{news.data?.[0]?.news_title}</p>
                        </Link>
                    </div>
                </Col>

                <Col xs={24} xl={12}>
                    {news?.data?.slice(2, 6).map((item, index) => (
                        <div key={index} className={isMobile ? "mx-[30px]" : undefined}>
                            <Link to={"/tin-tuc/" + item?.news_slug} className='grid grid-cols-[30%,70%] gap-2 mb-5'>
                                <div className=''>
                                    <img src={item?.news_image} alt="movie" className={isMobile ? "transition-opacity duration-300 h-[110px]" : "w-full h-full  transition-opacity duration-300"} />
                                </div>
                                <div className=''>
                                    <p className={isMobile ? "text-[#42495B] font-bold text-sm line-clamp-2" : "text-[#42495B] font-bold text-lg line-clamp-2"}>{item?.news_title}</p>
                                    <p className={isMobile ? "text-[#42495B] text-sm line-clamp-3 mt-2" : "text-[#42495B] text-base mt-1 line-clamp-3"}>{parse(item?.news_description)}</p>
                                </div>
                            </Link>
                        </div>
                    ))}
                </Col>
            </Row>
        </div>
    )
}

export default New
