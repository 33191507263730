import React, { useState } from "react";
import styles from "./style.module.css";
// import axiosInterceptorInstance from "";
import axios from "axios";
import { message } from "antd";
import { useCookies, Cookies } from "react-cookie";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import faEye from "../loginAdmin/img/show.svg";
import faEyeSlash from "../loginAdmin/img/unshow.svg";
import axiosInstance from "../../../until/axios";
export default function App() {
  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const [cookies, setCookie] = useCookies(["token"]);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!state.username || !state.password) {
      message.error("Làm ơn nhập tên và mật khẩu");
      return;
    }  
    console.log(state, "ssssssssssss");
    await axiosInstance
      .post("/admin/login", state)
      .then(function (response) {
        setCookie("token", response?.data);
        localStorage.setItem("token", response?.data);
        message.success("Đăng nhập thành công!");
        navigate("/admin/users");
      })
      .finally()
      .catch(function (error) {
        message.error("Tài khoản hoặc mật khẩu không đúng!");
      });
  };

  return (
    <div className="h-[800px] flex flex-col justify-center ">
      <div className="">
        <div className="">
          <div className="text-6xl pb-5 font-bold flex items-center justify-center text-[#FA4613]">
            LOGIN
          </div>
          <div className="flex items-center justify-center">
            {" "}
            <form onSubmit={handleSubmit} className="w-[50%]">
              {/* <div className="form-control"> */}
              <div className={`${styles.formControl} `}>
                <label>Email</label>
                <input
                  type="text"
                  name="username"
                  value={state.username}
                  onChange={handleInputChange}
                  className="rounded-lg"
                />
              </div>
              <div className={`${styles.formControl}`}>
                <label>Password</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={state.password}
                    onChange={handleInputChange}
                    className="rounded-lg pr-10"
                  />
                  {showPassword ? (
                    <img
                      src={faEye}
                      alt="Hide Password"
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <img
                      src={faEyeSlash}
                      alt="Show Password"
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  )}
                </div>
              </div>

              <div className="flex items-center justify-center">
                <button className="h-[40px] w-[120px] transform rounded-[10px] bg-[#FA4613] hover:border-[2px] hover:border-white px-[18px] py-[8px] font-montserrat text-[12px] font-semibold text-white transition duration-300 hover:scale-105 hover:bg-red-600">
                  LOGIN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
