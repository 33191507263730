import banner from "../../component/Image/images/Banner-service.png";
import { Row, Col, message } from "antd";
import { useEffect, useState, React } from "react";
import { useDevice } from "../../hook/useDevice";
import AnimationLine from "../../component/animationLine";
import dayjs from "dayjs";
import axiosInstance from "../../until/axios";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';

const News = () => {
  const { isMobile } = useDevice();
  const [news, setNews] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    pageSite: 2,
  });

  const fetchCareer = async () => {
    await axiosInstance
      .get(`/news/getAll`, { params: pagination })
      .then((res) => {
        const data = res?.data;
        setNews(data || []);
      })
      .catch(() => message.error("Error server!"));
  };

  useEffect(() => {
    fetchCareer();
  }, [pagination]);

  return (
    <div className="mt-[60px]">
      <div
        className="h-[200px] pl-[10%] pr-[10%]"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <p className="pt-[50px] font-bold text-2xl text-white">TIN TỨC</p>
        <div className="flex mt-5">
          <p className="text-xl text-white">Trang chủ /</p>
          <p className="text-[#FA4613] pl-2 text-xl">Tin tức</p>
        </div>
      </div>
      <div className={isMobile ? "pl-[3%] pr-[3%] pt-10" : "pl-[10%] pr-[10%] pt-10"}>

        <Row>
          <Col xs={24} xl={12} className={isMobile ? "" : "pr-5"}>
            <div className={isMobile ? "mb-5" : undefined}>
              <Link to={"/tin-tuc/" + news?.data?.[0]?.news_slug}>
                <div className={isMobile ? "" : undefined}>
                  <img src={news.data?.[0]?.news_image} alt="movie" className="w-full h-full  transition-opacity duration-300" />
                </div>
                <p className={isMobile ? "text-[#42495B] mt-3 text-xl font-bold" : "text-[#42495B] mt-3 text-2xl font-bold line-clamp-2 lg:pb-2 pb-0"}>{news.data?.[0]?.news_title}</p>
              </Link>
            </div>
            <Row>
              {isMobile ? <>
                {news?.data?.slice(4, 8).map((item, index) => (
                  <div key={index} className={isMobile ? "" : undefined}>
                    <Link to={"/tin-tuc/" + item?.news_slug} className='grid grid-cols-[30%,70%] gap-2 mb-3'>
                      <div className=''>
                        <img src={item?.news_image} alt="movie" className={isMobile ? "transition-opacity duration-300 h-[110px]" : "w-full h-full  transition-opacity duration-300"} />
                      </div>
                      <div className=''>
                        <p className={isMobile ? "text-[#42495B] font-bold text-sm line-clamp-2" : "text-[#42495B] font-bold text-lg line-clamp-2"}>{item?.news_title}</p>
                        <p className={isMobile ? "text-[#42495B] text-sm line-clamp-3 mt-2" : "text-[#42495B] text-base mt-1 line-clamp-3"}>{parse(item?.news_description)}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </> : <>
                <Col xs={24} xl={12}>
                  <div className={isMobile ? "mx-[30px]" : "mr-3"}>
                    <Link to={"/tin-tuc/" + news?.data?.[1]?.news_slug}>
                      <div className="h-[195px]">
                        <img src={news.data?.[1]?.news_image} alt="movie" className="w-full h-full  transition-opacity duration-300" />
                      </div>
                      <p className={isMobile ? "text-[#42495B] mt-3 text-xl font-bold" : "text-[#42495B] mt-3 text-2xl font-bold line-clamp-2"}>{news.data?.[1]?.news_title}</p>
                    </Link>
                  </div>
                </Col>
                <Col xs={24} xl={12}>
                  <div className={isMobile ? "mx-[30px]" : "mr-3"}>
                    <Link to={"/tin-tuc/" + news?.data?.[2]?.news_slug}>
                      <div className="h-[195px]">
                        <img src={news.data?.[2]?.news_image} alt="movie" className="w-full h-full  transition-opacity duration-300" />
                      </div>
                      <p className={isMobile ? "text-[#42495B] mt-3 text-xl font-bold" : "text-[#42495B] mt-3 text-2xl font-bold line-clamp-2"}>{news.data?.[2]?.news_title}</p>
                    </Link>
                  </div>
                </Col>
              </>}
            </Row>
          </Col>
          <Col xs={24} xl={12}>
            {news?.data?.slice(2, 6).map((item, index) => (
              <div key={index} className={isMobile ? "" : undefined}>
                <Link to={"/tin-tuc/" + item?.news_slug} className={isMobile ? " grid grid-cols-[30%,70%] gap-2 mb-3" : "grid grid-cols-[30%,70%] gap-2 mb-12"}>
                  <div className=''>
                    <img src={item?.news_image} alt="movie" className={isMobile ? "transition-opacity duration-300 h-[110px]" : "w-full h-full  transition-opacity duration-300"} />
                  </div>
                  <div className=''>
                    <p className={isMobile ? "text-[#42495B] font-bold text-sm line-clamp-2" : "text-[#42495B] font-bold text-lg line-clamp-2"}>{item?.news_title}</p>
                    <p className={isMobile ? "text-[#42495B] text-sm line-clamp-3 mt-2" : "text-[#42495B] text-base mt-1 line-clamp-3"}>{parse(item?.news_description)}</p>
                  </div>
                </Link>
              </div>
            ))}
          </Col>
        </Row>
      </div>
      {/* ------------------ */}
      <div className="px-[10%] pb-5 mt-5">
        <p className="text-[#42495B] text-center font-bold py-5 text-2xl">
          GIẢI PHÁP NGHIỆP VỤ BỘ CÔNG AN
        </p>
        <div className="">
          <AnimationLine />
        </div>
        <div></div>
        <Row className="py-5">
          {news.data?.map((_, index) => (
            <>
              {" "}
              {index > 6 && (
                <>
                  <Col xs={24} xl={8} className={isMobile ? "py-2" : "py-5"}>
                    <Link to={"/tin-tuc/" + _?.news_slug}>
                      <div className={isMobile ? "flex justify-center my-2 h-[170px] w-[300px] mx-auto pl-0" : "flex justify-center my-2 h-[300px] w-[auto] mx-auto pl-2"}>
                        <img
                          className="h-full w-full"
                          src={
                            _?.news_image
                          }
                          alt="Picture of the author"
                        />
                      </div>
                      <div
                        className={
                          isMobile ? "pt-1 text-black" : " px-[40px] pt-1 text-black"
                        }
                      >
                        <p className="font-semibold text-xl line-clamp-3">
                          {_?.news_title}
                        </p>
                      </div>

                    </Link>
                  </Col>
                </>
              )}
            </>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default News;
