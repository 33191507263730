import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDevice } from '../../hook/useDevice';
import AnimationLine from '../animationLine';
import IconBag from '../Image/images/bag.png'
import IconArrowRight from '../Image/images/right-arrow.png'

const sliderImageUrl = [
  {
    url:
      "https://itech.edu.vn/wp-content/uploads/2021/12/Anh-dai-dien-bai-CNTT-voi-cuoc-song.jpg",
    title: 'chuyển đổi số doanh nghiệp',
    text_1: 'Khai báo hải quan',
    text_2: 'Quản lý kho bãi',
    text_3: 'Thiết kế hệ thống CRM quản lý doanh nghiệp',
    text_4: 'Quản lý vận hành khai thác cho sân bay và cảng',
    text_5: 'Quản lý vận hành khai thác cho sân bay và cảng',
  },
  {
    url:
      "https://htt.edu.vn/wp-content/uploads/2018/10/b%E1%BA%A1n-c%C3%B3-ph%C3%B9-h%E1%BB%A3p-v%E1%BB%9Bi-ng%C3%A0nh-CNTT-kh%C3%B4ng-1.jpg",
    title: 'Tự động hóa hệ thống',
    text_1: 'Kiểm soát tự động hóa',
    text_2: 'Tối ưu hóa hệ thống',
    text_3: 'Tích hợp dữ liệu',
    text_4: 'Tích hợp máy móc',
    text_5: 'Xây dựng hệ thống tự động hóa',
  },
  //Second image url
  {
    url: "https://itech.edu.vn/wp-content/uploads/2021/12/Anh-dai-dien-bai-CNTT-voi-cuoc-song.jpg",
    title: 'Giải pháp ứng dụng di động',
    text_1: 'Phát triển ứng dụng di động',
    text_2: 'Tối ưu hóa trải nghiệm người dùng',
    text_3: 'Kết nối dữ liệu di động',
    text_4: 'Phát triển ứng dụng di động đa nền tảng',
    text_5: 'Xây dựng giải pháp di động cá nhân hóa',
  },
  //Third image url
  {
    url:
      "https://htt.edu.vn/wp-content/uploads/2018/10/b%E1%BA%A1n-c%C3%B3-ph%C3%B9-h%E1%BB%A3p-v%E1%BB%9Bi-ng%C3%A0nh-CNTT-kh%C3%B4ng-1.jpg",
    title: 'Xây dựng ứng dụng web',
    text_1: 'Phát triển ứng dụng web',
    text_2: 'Tối ưu hóa trải nghiệm người dùng',
    text_3: 'Kết nối dữ liệu web',
    text_4: 'Phát triển ứng dụng web đa nền tảng',
    text_5: 'Xây dựng giải pháp web cá nhân hóa',
  },


  {
    url: "https://itech.edu.vn/wp-content/uploads/2021/12/Anh-dai-dien-bai-CNTT-voi-cuoc-song.jpg",
    title: 'Phát triển trò chơi',
    text_1: 'Thiết kế trò chơi',
    text_2: 'Lập trình trò chơi',
    text_3: 'Kiểm thử trò chơi',
    text_4: 'Tối ưu hóa trò chơi',
    text_5: 'Phát hành trò chơi',
  }
];


const Technology = () => {
  const { isMobile } = useDevice();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow:  isMobile ? 1 : 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    arrows: false,
  };
  

  return (
    <div className='container mx-auto my-5'>
      <div className='pb-5'>
        <h1 className={isMobile ? "text-center text-[1.5rem] font-bold text-[#42495B]" : "text-center text-[1.875rem] font-bold text-[#42495B]"}>Tiên phong dẫn đầu<br></br>về công nghệ</h1>
      </div>
      <div className="pb-5">
        <AnimationLine/>
      </div>

      <div className={isMobile ? "mx-[30px]" : ""}>
      <Slider {...settings}>
      {sliderImageUrl.map((imageUrl, index) => {
          return (
            <div className={isMobile ? "relative h-48" : "relative px-8 h-72"} key={index}>
              <img src={imageUrl.url} alt="movie" className="w-full h-full transition-opacity duration-300" />
              <div className={isMobile? "absolute inset-0 flex flex-col bg-white bg-opacity-75 opacity-0 hover:opacity-100 transition-opacity duration-300 pt-5 pl-6 pr-7" : "absolute inset-0 flex flex-col bg-white bg-opacity-95 opacity-0 hover:opacity-100 transition-opacity duration-300 pt-4 px-[20px] mx-[31px]"}>
                <div className={isMobile ? "flex items-center mb-2 " : "flex items-center mb-4"}>
                  <img src={IconBag} className="h-6 w-6 text-[#FA4613] mr-2"></img>
                  <p className="text-sm text-[#FA4613] font-bold">{imageUrl.title}</p>
                </div>
                <div className={isMobile ? "flex items-center " : "flex items-center mb-4"}>
                  <img src={IconArrowRight} className="h-6 w-3 text-[#014BAD] mr-2"></img>

                  <a href='' className='hover:underline text-xs text-[#282828]'>{imageUrl.text_1}</a>
                </div>
                <div className={isMobile ? "flex items-center " : "flex items-center mb-4"}>
                <img src={IconArrowRight} className="h-6 w-3 text-[#014BAD] mr-2"></img>

                  <a href='' className='hover:underline text-xs text-[#282828]'>{imageUrl.text_2}</a>
                </div>
                <div className={isMobile ? "flex items-center " : "flex items-center mb-4"}>
                  <img src={IconArrowRight} className="h-6 w-3 text-[#014BAD] mr-2"></img>

                  <a href='' className='hover:underline text-xs text-[#282828]'>{imageUrl.text_3}</a>
                </div>
                <div className={isMobile ? "flex items-center " : "flex items-center mb-4"}>
                  <img src={IconArrowRight} className="h-6 w-3 text-[#014BAD] mr-2"></img>

                  <a href='' className='hover:underline text-xs text-[#282828]'>{imageUrl.text_4}</a>
                </div>
                <div className={isMobile ? "flex items-center " : "flex items-center mb-4"}>
                  <img src={IconArrowRight} className="h-6 w-3 text-[#014BAD] mr-2"></img>

                  <a href='' className='hover:underline text-xs text-[#282828]'>{imageUrl.text_5}</a>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      </div>
        

    </div>
  )
}

export default Technology
