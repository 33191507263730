import CareersForm from "../../../component/CareerForm";
import { Form, message } from "antd"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axiosInstance from "../../../until/axios";
export default function CareersDetails() {
    
  const [form] = Form.useForm();
  const params = useParams();
  const career_id = params?.id;
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();

  const fetchCareer = async () => {
    await axiosInstance
      .get(`/career/getById/${params?.id}`)
      .then((res) => {
        const data = res?.data;
        const values = {
          ...data,
        };
        console.log(params?.id, values);
        setInitialValues(values);
      });
  };

  const createCreers = async (values) => {
    await axiosInstance.post(`/career/create`, values);
  };

  const updateCreers = async (id, values) => {
    await axiosInstance.post(
      `/career/update/${id}`,
      values
    );
  };
    
  const onSubmit = async (values) => {
    const submitValues = {
      ...values,
    };
  
    try {
      if (career_id && career_id !== "create") {
        await updateCreers(career_id, submitValues);
        message.success("Cập nhập thành công");
        navigate("/admin/career");
      } else {
        await createCreers(submitValues);
        message.success("Tạo mới thành công");
        navigate("/admin/career");
      }
    } catch (error) {
      if(error){
        message.error("Bài viết đã tồn tại");
      }
    }
  };

  useEffect(() => {
    if (career_id && career_id !== "create") {
      fetchCareer();
    }
  }, [career_id]);

  useEffect(()=>{
    if (career_id) fetchCareer();
    form.resetFields();
  },[form, career_id])


  return (
    <div className="p-[40px] bg-white rounded-[10px]">
      <CareersForm
        id={career_id !== "create" ? career_id : undefined}
        initialValues={initialValues}
        onSubmit={onSubmit} />
    </div>
  )
}