import * as React from "react";

import { motion, useAnimation } from "framer-motion";
// import { useTranslation } from 'next-i18next';
import { useInView } from "react-intersection-observer";
import elip from "../Image/images/Ellipse.svg";

const fadeDown = {
  hidden: {
    opacity: 0,
    y: -100,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const showUp = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const zoomIn = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

export default function AnimationLine() {
  // const { t, ready } = useTranslation('about');

  const [ref, inView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });
  const animation = useAnimation();

  React.useEffect(() => {
    if (inView) {
      animation.start("visible");
    }
  }, [inView, animation]);

  return (
    <div>
      <div className="">
        <section
          className="mt-0 md:mt-[0px] xl:px-10 2xl:px-[10%]"
          ref={ref}
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div
            className={`no-scrollbar relative overflow-y-hidden overflow-x-scroll md:overflow-visible`}
          >
            {/* <motion.div
                className="hidden lg:shrink-1 absolute left-0 top-[320px] !ml-0 md:flex w-full shrink-0 items-center justify-center md:top-[0px] md:px-[32px] lg:top-[0px] lg:w-full xl:top-[0px] xl:px-[0px] 2xl:top-[0px] 3xl:top-[0px]"
                initial="hidden"
                animate={animation}
                variants={zoomInVariants}
                transition={{ delay: 1, duration: 0.3 }}
              >
                <Image src={elip} alt="" className="" />
              </motion.div> */}

            <motion.div
              className="animate-zoom-in scale-120 mx-auto w-[8px] h-[8px] "
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={zoomIn}
            >
              <img
                className="rounded-[24px] mr-2 "
                src={elip}
                alt="Picture of the author"
                width={12}
                height={12}
              />
            </motion.div>
          </div>
        </section>
      </div>
    </div>
  );
}
