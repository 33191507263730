import React from 'react'
import LogoCa1 from "../../component/Image/images/logo_bo_cong_an.svg"
import LogoCa2 from "../../component/Image/images/logo_bo_cong_an_2.svg"
import LogoVietin from "../../component/Image/images/logo_vietin.svg"
import LogoBidv from "../../component/Image/images/logo_bidv.svg"
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AnimationLine from '../animationLine'
import { useDevice } from '../../hook/useDevice'
const dataLogo =[
  {
    image: LogoCa1
  },
  {
    image: LogoCa2
  },
  {
    image: LogoVietin
  },
  {
    image: LogoBidv
  },
]
const Partner = () => {
  const { isMobile } = useDevice();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow:  isMobile ? 3 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    arrows: false,
  };
  return (
    <div className='container mx-auto mb-5'>
      <div className='pb-5'>
        <h1 className={isMobile ? "text-center text-[1.5rem] font-bold text-[#42495B]" : "text-center text-[1.875rem] font-bold text-[#42495B]"}>Khách hàng và đối tác</h1>
      </div>
      <div className='pb-5'>
        <AnimationLine/>
      </div>

      <div className='bg-[#D8E9FF]'>
        <Slider {...settings}>
          {dataLogo.map((data, index) => {
            return (
              <div key={index} className='py-3 flex justify-center'>
                <img 
                  src={data.image}
                  alt="logo"
                  className={isMobile ? "h-8 object-contain" : "h-20 object-contain"}
                  style={{ margin: "auto" }}
                  
                >
                </img>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default Partner
