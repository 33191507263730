import { Button, Form, Input, Modal, Row, Space, message } from "antd";
import axios from "axios";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router"
import { Link } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import CustomUpload from "../customUpload";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axiosInstance from "../../until/axios";

export default function CareersForm({
    id = "",
    initialValues = {},
    onSubmit = () => { },
}) {

    const navigate = useNavigate();
    const [form] = Form.useForm();    

    const deleteCareer = async () => {
        await axiosInstance.delete(`/career/delete/${id}`)
            .then(() => message.success("Xoá bài tuyển dụng thành công"))
    }

    useEffect(() => {
        if (Object.keys(initialValues)?.length > 0) {
            form.resetFields();
          }
    }, [form, initialValues])

    const removeCareer = async () => {
        try {
          await deleteCareer();
          return navigate("/admin/career");
        } catch (err) {
          console.log(err.message);
        }
      };

    const confirmDeleteBusiness = () => {
        Modal.confirm({
          icon: <ExclamationCircleOutlined />,
          content: "Bạn có chắc chắn xoá bài tuyển dụng này?",
          okText: "Xác nhận",
          cancelText: "Huỷ",
          onOk: () => removeCareer(),
        });
      };
    return (
        <div className="p-[40px] bg-white rounded-[10px]">
            <div className={"!text-[#2d2e32] pb-[10px]"}>
                <Link
                    to={"/admin/career"}
                    className={
                        "text-[18px] sm:text-[24px] md:text-[26px] xl:text-[26px] font-[500] cursor-pointer "
                    }
                >
                    {"Thông tin bài viết"}
                </Link>
                <Form          
                    layout={"vertical"}
                    colon={false}
                    form={form}
                    initialValues={initialValues}
                    onFinishFailed={(e) => console.log(e)}
                    onFinish={onSubmit}>
                    <Form.Item
                        label={"Tên bài viết"}
                        name="career_title"
                        rules={[{ required: true, message: "Vui lòng nhập nội dung!" }]}
                    >
                        <Input size="large" placeholder={"Nhập"} />
                    </Form.Item>
                    <Form.Item
                        name="career_description"
                        label={"Mô tả"}
                        rules={[{ required: true, message: "Vui lòng nhập nội dung!" }]}
                    >
                        <ReactQuill className="h-[400px] pb-10" theme="snow" />
                    </Form.Item>
                    <Form.Item
                        name="career_image"
                        label={"Link ảnh"}
                        rules={[{ required: true, message: "Vui lòng chọn file!" }]}
                    >
                        <CustomUpload type="image" accept=".png, .jpg, .jpeg, .jfif"/>
                    </Form.Item>
                    <Row gutter={40} className={"my-[40px] pl-[20px]"}>
                        <Space align="center">
                            <Button type={"primary"} htmlType={"submit"}>
                                {id ? "Cập nhập" : "Tạo"}
                            </Button>
                            {id && (
                                <Button type={"primary"} danger onClick={confirmDeleteBusiness}>
                                    Xoá
                                </Button>
                            )}
                        </Space>
                    </Row>
                </Form>
            </div>
        </div>
    )
}