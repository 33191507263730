import AnimationCircle from "../../component/AnimationCircle/index";
// import AnimationDot from "@/app/components/common/AnimationDot";
// import Line from "@/components/common/Line";
import { useDevice } from "../../hook/useDevice";
import image1 from "../../component/Image/images/1.png";
import banner from "../../component/Image/images/Banner-service.png";
import { Col, Row, message } from "antd/lib";
import AnimationLine from "../../component/animationLine";
import { useState, React, useEffect } from "react";
import axiosInstance from "../../until/axios";
import { Link } from "react-router-dom";
const Service = () => {
  const { isMobile } = useDevice();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    pageSite: 1,
  });

  const fetchCareer = async () => {
    await axiosInstance
      .get(`/news/getAll`, { params: pagination })
      .then((res) => {
        const data = res?.data;
        setData(data || []);
      })
      .catch(() => message.error("Error server!"));
  };

  useEffect(() => {
    fetchCareer();
  }, [pagination]);
  console.log(data, "datadatadata");

  return (
    <div className="mt-[60px]">
      <div
        className="h-[200px] pl-[10%] pr-[10%]"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <p className="pt-[50px] font-bold text-2xl text-white">DỊCH VỤ</p>
        <div className="flex mt-5">
          <p className="text-xl text-white">Trang chủ /</p>
          <p className="text-[#FA4613] pl-2 text-xl">Dịch vụ</p>
        </div>
      </div>
      <div className="pl-[10%] pr-[10%] pt-20 ">
        <p className="text-[#42495B] text-center font-bold text-2xl mb-5">
          GIẢI PHÁP NGHIỆP VỤ BỘ CÔNG AN
        </p>
        <div className="">
          <AnimationLine />
        </div>
        <div className="pt-5">
          <div className="flex">
            {/* <div className="flex-none w-[10px] h-[10px] bg-[#FA4613] rounded-full mt-2"></div> */}
            <div className="flex-none  h-[10px]  rounded-full mt-2">
              <AnimationCircle />
            </div>
            <p className="flex-1 text-black pl-3 text-lg">
              Homicen là nhà cung cấp giải pháp và triển khai xây dựng các giải
              pháp nghiệp vụ Bộ Công an, góp phần cải cách hành chính, nâng cao
              chất lượng, hiệu quả các mặt công tác Công an, bảo vệ an ninh quốc
              gia, bảo đảm trật tự, an toàn xã hội, phục vụ chuyển đổi số.
            </p>
          </div>
          <div className="flex mt-5">
            {/* <div className="flex-none w-[10px] h-[10px] bg-[#FA4613] rounded-full mt-2"></div> */}
            <div className="flex-none  h-[10px]  rounded-full mt-2">
              <AnimationCircle />
            </div>
            <p className="flex-1 text-black pl-3 text-lg">
              Homicen là nhà cung cấp giải pháp và triển khai xây dựng các giải
              pháp nghiệp vụ Bộ Công an, góp phần cải cách hành chính, nâng cao
              chất lượng, hiệu quả các mặt công tác Công an, bảo vệ an ninh quốc
              gia, bảo đảm trật tự, an toàn xã hội, phục vụ chuyển đổi số.
            </p>
          </div>
        </div>
      </div>
      <div className="pl-[10%] pr-[10%] pt-20 pb-10">
        <p className="text-[#42495B] text-center font-bold py-5 text-2xl">
          GIẢI PHÁP NGHIỆP VỤ BỘ CÔNG AN
        </p>
        <div className="">
          <AnimationLine />
        </div>
        <Row className="">
          {data.data?.map((_, index) => (
            <>
              {" "}
              {
                <>
                  <Col xs={24} xl={8} className={isMobile ? "py-2" : "py-5"}>
                    <Link to={"/tin-tuc/" + _?.news_slug}>
                      <div className={isMobile ? "flex justify-center my-2 h-[170px] w-[300px] mx-auto pl-0" : "flex justify-center my-2 h-[300px] w-[auto] mx-auto pl-2"}>
                        <img
                          className="h-full w-full"
                          src={
                            _?.news_image
                          }
                          alt="Picture of the author"
                        />
                      </div>
                      <div
                        className={
                          isMobile ? "pt-1 text-black" : " px-[40px] pt-1 text-black"
                        }
                      >
                        <p className="font-semibold text-xl line-clamp-3">
                          {_?.news_title}
                        </p>
                      </div>
                    </Link>
                  </Col>
                </>
              }
            </>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Service;
