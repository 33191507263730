import { useState } from "react";
// import CircleImg from "/public/common/circle-new.svg";

import BackgroundSlider from "../../Image/images/bg_Our_capacity.svg";

import CreativeIcon from "../../Image/different/creative-icon-new.svg";

import LimitlessIcon from "../../Image/different/limitless-icon-new.svg";

import PatientIcon from "../../Image/different/patient-icon-new.svg";
// import ReliableIcon from "@/public/different/reliable-icon-new.svg";
import ReliableIcon from "../../Image/different/reliable-icon-new.svg";
import bg from "../../Image/different/reliable-icon-new.svg";
import styles from "./WhatMakesUsDifferent.module.css";
// import Image from "next/image";
import CountUp from 'react-countup';
import { useDevice } from "../../../hook/useDevice";
// import { useTranslation } from "next-i18next";

const listContent = {
    name: "",
    imgPath: "",
    content: "",
  };

function WhatMakeUsDifferent() {
    const [list, setList] = useState([
      {
        name: "patient",
        imgPath: PatientIcon,
        content: "Persevere in solving problems",
      },
      {
        name: "reliable",
        imgPath: ReliableIcon,
        content: "Provide the best products and services on time",
      },
      {
        name: "creative",
        imgPath: CreativeIcon,
        content: "Be young, dynamic and creative",
      },
      {
        name: "limitless",
        imgPath: LimitlessIcon,
        content: "Never stop learning and growing",
      },
    ]);
  const { isMobile } = useDevice();

  
    // Rest of the component code...
  
//   const { t, ready } = useTranslation("wmud");

  return (
    <section className="px-4 md:px-0 xl:px-10 2xl:px-[5%]">
      <div
        className={`${styles.section} mt-9 md:mt-[30px] xl:mt-[110px] 3xl:mt-[120px]`}
      >
        <div
style={{
    backgroundImage: `url(${BackgroundSlider})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  }}
        >
          <div className="flex flex-col items-center pt-8">
            <div className={isMobile ? "font-sans font-bold text-[1.5rem] text-white" : "font-sans font-bold text-[1.875rem] text-white"}>
              Năng lực của chúng tôi
            </div>
            <div className={isMobile ? "container grid grid-cols-2 px-1 py-[30px]" : "container lg:grid lg:grid-cols-6 px-1 py-[64px] grid-cols-none"}>
              {/*  */}
              <div className={isMobile ? "flex flex-col items-start pl-5 mb-7" : "flex flex-col items-center"}>
                <div className="flex">
                  <div className={isMobile ? "font-sans text-[25px] font-bold text-white" : "font-sans text-[26px] font-bold text-white"}>
                    <CountUp end={400} duration={10}></CountUp>
                  </div>
                  <div className={isMobile ? "font-sans text-[25px] font-medium text-white" : "font-sans text-3xl font-medium text-white"}>+</div>
                </div>
                <div className={isMobile ? "font-sans text-[14px] font-bold text-white" : "font-sans text-[1.25rem] font-bold text-white"}>Dự án công nghệ</div>
              </div>
              {/*  */}
              <div className={isMobile ? "flex flex-col items-start pl-5 mb-7" : "flex flex-col items-center"}>
                <div className="flex">
                  <div className={isMobile ? "font-sans text-[25px] font-bold text-white" : "font-sans text-[26px] font-bold text-white"}>
                  <CountUp end={100} duration={10}></CountUp>
                  </div>
                  <div className={isMobile ? "font-sans text-[25px] font-medium text-white" : "font-sans text-3xl font-medium text-white"}>+</div>
                </div>
                <div className={isMobile ? "font-sans text-[14px] font-bold text-white" : "font-sans text-[1.25rem] font-bold text-white"}>Giải thưởng</div>
              </div>
              {/*  */}
              <div className={isMobile ? "flex flex-col items-start pl-5 mb-7" : "flex flex-col items-center"}>
                <div className="flex">
                  <div className={isMobile ? "font-sans text-[25px] font-bold text-white" : "font-sans text-[26px] font-bold text-white"}>
                  <CountUp end={5000} duration={10}></CountUp>
                  </div>
                  <div className={isMobile ? "font-sans text-[25px] font-medium text-white" : "font-sans text-3xl font-medium text-white"}>+</div>
                </div>
                <div className={isMobile ? "font-sans text-[14px] font-bold text-white" : "font-sans text-[1.25rem] font-bold text-white"}>Khách hàng lớn</div>
              </div>
              {/*  */}
              <div className={isMobile ? "flex flex-col items-start pl-5 mb-7" : "flex flex-col items-center"}>
                <div className="flex">
                  <div className={isMobile ? "font-sans text-[25px] font-bold text-white" : "font-sans text-[26px] font-bold text-white"}>
                  <CountUp end={1000} duration={10}></CountUp>
                  </div>
                  <div className={isMobile ? "font-sans text-[25px] font-medium text-white" : "font-sans text-3xl font-medium text-white"}>+</div>
                </div>
                <div className={isMobile ? "font-sans text-[14px] font-bold text-white" : "font-sans text-[1.25rem] font-bold text-white"}>Nhân sự</div>
              </div>
              {/*  */}
              <div className={isMobile ? "flex flex-col items-start pl-5 mb-7" : "flex flex-col items-center"}>
                <div className="flex">
                  <div className={isMobile ? "font-sans text-[25px] font-bold text-white" : "font-sans text-[26px] font-bold text-white"}>
                  <CountUp end={3} duration={10}></CountUp>
                  </div>
                  <div className={isMobile ? "font-sans text-[25px] font-medium text-white" : "font-sans text-3xl font-medium text-white"}>+</div>
                </div>
                <div className={isMobile ? "font-sans text-[14px] font-bold text-white" : "font-sans text-[1.25rem] font-bold text-white"}>Chi nhánh</div>
              </div>
              {/*  */}
              <div className={isMobile ? "flex flex-col items-start pl-5 mb-7" : "flex flex-col items-center"}>
                <div className="flex">
                  <div className={isMobile ? "font-sans text-[25px] font-bold text-white" : "font-sans text-[26px] font-bold text-white"}>
                  <CountUp end={100} duration={10}></CountUp>
                  </div>
                  <div className={isMobile ? "font-sans text-[25px] font-medium text-white" : "font-sans text-3xl font-medium text-white"}>+</div>
                </div>
                <div className={isMobile ? "font-sans text-[14px] font-bold text-white" : "font-sans text-[1.25rem] font-bold text-white"}>Đối tác chiến lược</div>
              </div>
              {/*  */}

              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhatMakeUsDifferent;
