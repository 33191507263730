import { Route, Routes } from "react-router-dom";
import AboutUs from "../page/aboutUs";
import News from "../page/news";
import Service from "../page/Service";
import Home from "../page/user/Home";
import Contact from "../page/contact";
import Careers from "../page/careers";
import CareersId from "../page/careers/details";
import LoginAdmin from "../page/admin/loginAdmin";
import NewsDetail from "../page/news/details";
export default function User() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/news" element={<News />} />
      <Route path="/service" element={<Service />} />
      <Route path="/tin-tuc/:slug" element={<NewsDetail />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/tuyen-dung/:id" element={<CareersId />} />
      <Route path="/loginAdmin" element={<LoginAdmin />} />
    </Routes>
  );
};