import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Image, Modal, Pagination, Row, Space, Table, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../../until/axios";
import SearchProps from "../../../component/SearchProps";


export default function AboutUsManager() {

    const [newData, setNew] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 10,
        pageSite: 3
    });

    const fetchNew = async () => {
        await axiosInstance.get(`/news/getAll/`, { params: pagination }).then((res) => {
            const data = res?.data;
            console.log(data)
            setNew(data || []);
        }).catch(() => {
            message.error("Error server!");
        });
    }

    const deleteNew = async (news_id) => {
        await axiosInstance.delete(`/news/delete/${news_id}`).finally(() => {
            fetchNew();
            message.success("Xoá này thành công");
        })
    }

    const confirmDelete = (news_id) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có muốn xóa comment này?",
            okText: "Xac nhan",
            cancelText: "Huy",
            onOk: () => deleteNew(news_id)
        })
    }

    useEffect(() => {
        fetchNew();
    }, [pagination])

    const columns = [
        {
            title: <div className={"base-table-cell-label"}>ID</div>,
            key: "news_id",
            dataIndex: "news_id",
            sorter: (a, b) => a.news_id - b.news_id,
            width: 50,
            render: (_, record) => <div>{record?.news_id}</div>,
        },
        {
            title: <div className={"base-table-cell-label"}>Tên bài viết</div>,
            key: "news_title",
            dataIndex: "news_title",
            ...SearchProps("news_title"),
            render: (_, record) => {
                return <div>{record?.news_title}</div>;
            },
        },
        {
            title: <div className={"base-table-cell-label"}>Mô tả bài viết</div>,
            key: "news_description",
            dataIndex: "news_description",
            render: (_, record) => {
                return <div>{record?.news_description?.slice(0, 20)}</div>;
            },
        },
        {
            title: <div className={"base-table-cell-label"}>Hình ảnh</div>,
            key: "news_image",
            dataIndex: "news_image",
            render: (_, record) => {
                return <div><Image src={record?.news_image} height={100} /></div>;
            },
        },
        {
            title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
            key: "create_at",
            dataIndex: "create_at",
            width: 280,
            sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
            render: (_, record) => {
                return (
                    <div>
                        <span className={"!inline-block min-w-[100px]"}>
                            {dayjs(record?.create_at).format("DD/MM/YYYY")}
                        </span>
                    </div>
                );
            },
        },
        {
            key: "operation",
            dataIndex: "operation",
            width: 100,
            render: (_, record) => {
                return (
                    <Space>
                        <Link
                            to={`/admin/about_us/${record?.news_id}`}
                            className={"text-[var(--blue)]"}
                        >
                            <EditOutlined />
                        </Link>
                        <div
                            className={"text-[var(--red)]"}
                            onClick={() => confirmDelete(record?.news_id)}
                        >
                            <DeleteOutlined />
                        </div>
                    </Space>
                );
            },
        },
    ]

    return (
        <>
            <Row gutter={10} className={"mb-[8px]"}>
                <Col flex={1}>
                    <div className={"text-[20px] font-medium"}>Bài viết</div>
                </Col>
                <Col>
                    <Link to={'/admin/about_us/create'}>
                        <Button type={"primary"} onClick={() => { }}>
                            Tạo
                        </Button>
                    </Link>
                </Col>
            </Row>

            <Table columns={columns} dataSource={newData?.data} pagination={false} ></Table>

            <Pagination
                className="flex justify-center absolute inset-x-0 bottom-10"
                current={pagination?.page}
                pageSize={pagination?.pageSize}
                total={newData?.total}
                showSizeChanger
                onChange={(page, pageSize) => {
                    setPagination({ page: page, pageSize: pageSize, pageSite: 3 });
                }}
            />

        </>
    )
}