
import React from "react";
import banner from "../../component/Image/images/Banner-service.png";
import { Row, Col, Divider } from "antd/lib";
import { useDevice } from "../../hook/useDevice";

const Contact = () => {
  const { isMobile } = useDevice();
  return (
    <div className=" mt-[60px] ">
      {/* banner */}
      <div
        className="h-[200px] pl-[10%] pr-[10%]"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <p className="pt-[50px] font-bold text-2xl text-white">LIÊN HỆ</p>
        <div className="flex mt-5">
          <p className="text-xl text-white">Trang chủ /</p>
          <p className="text-[#FA4613] pl-2 text-xl ">Liên hệ</p>
        </div>
      </div>

      {/* contact */}
      <div className="container mx-auto mt-5">
        <Row>
          {/* left */}
          <Col xs={24} xl={11}>
            <div className={isMobile ? "mt-[10px]" : "mt-[80px]"}>
              <p className="text-[#FA4613] text-2xl font-bold text-center">
                CÔNG TY TNHH HOMICEN <br></br> ĐÀ NẴNG
              </p>
              <div className="mt-3 flex justify-center">
                <Row>
                  <div className="mr-3">
                    <img
                      className="rounded-[24px]"
                      src={
                        "http://res.cloudinary.com/du5oymgsm/image/upload/v1710563467/d2gqicn8yk3jxotaqwcv.png"
                      }
                      alt="Picture of the author"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="mr-3">
                    <img
                      className="rounded-[24px]"
                      src={
                        "http://res.cloudinary.com/du5oymgsm/image/upload/v1710568923/vsebkifjpjhwvcpf4bkc.png"
                      }
                      alt="Picture of the author"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="mr-3">
                    <img
                      className="rounded-[24px]"
                      src={
                        "http://res.cloudinary.com/du5oymgsm/image/upload/v1710563672/eta4mf8eaerpmjhzkky6.png"
                      }
                      alt="Picture of the author"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="mr-3">
                    <img
                      className="rounded-[24px]"
                      src={
                        "http://res.cloudinary.com/du5oymgsm/image/upload/v1710563606/uf9uvldlxa7zfk8detxh.png"
                      }
                      alt="Picture of the author"
                      width={30}
                      height={30}
                    />
                  </div>
                </Row>
              </div>
            </div>
          </Col>
          {/* center */}
          <Col xs={24} xl={2}>
            <div className="flex justify-center">
              <div
                type={isMobile ? "horizontal" : "vertical"}
                className="h-[1px] lg:h-[250px] w-[1px] bg-black !important"
              />
            </div>
          </Col>
          {/* right */}
          <Col xs={24} xl={11} className="text-black pl-5 pr-3 lg:pl-0 lg-pr-0">
            <div className={isMobile ? "mt-[10px]" : "mt-[70px]"}>
              <p className="text-[#42495B]  text-lg font-bold">
                Địa chỉ:
                <span className="text-[#42495B]  text-lg font-normal ml-2">
                  05 Hà Bồng, Hoà Xuân, Cẩm Lệ, Đà Nẵng 550000, Việt Nam
                </span>
              </p>
              <p className="text-[#42495B]  text-lg font-bold mt-3">
                Số điện thoại:
                <span className="text-[#42495B]  text-lg font-normal ml-2">0915425563</span>
              </p>
              <p className="text-[#42495B]  text-lg font-bold mt-3">Email:
                <span className="text-[#42495B]  text-lg font-normal ml-2">
                  Support@homicenvn.com
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </div>
      {/* map */}
      <div className={isMobile ? "flex justify-center my-5 mx-5" : "flex justify-center my-20"}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.95161895424525!2d108.22469115356988!3d16.011159168141575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31421a1b393faaab%3A0x241bc774d65435e8!2zQ8O0bmcgVHkgVG5oaCBDw7RuZyBOZ2jhu4cgSG9taWNlbg!5e1!3m2!1svi!2s!4v1710207263268!5m2!1svi!2s"
          width={1000}
          height={isMobile ? "350" : "650"}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
