import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd/lib';
import IconPerson from '../../../component/Image/Footer/icon_person.png'
import LogoFb from '../../../component/Image/Footer/icon_fb.png';
import LogoX from '../../../component/Image/Footer/icon_x.png';
import LogoInsta from '../../../component/Image/Footer/icon_insta.png';
import LogoIn from '../../../component/Image/Footer/icon_in.png';
import IconTag from '../../../component/Image/images/price-tag.png';
import IconHome from '../../../component/Image/images/home.png';
import { Link, useParams } from "react-router-dom";
import AnimationLine from '../../../component/animationLine';
import { useDevice } from '../../../hook/useDevice';
import { message } from 'antd';
import axiosInstance from '../../../until/axios';
import moment from 'moment';
import parse from 'html-react-parser';



const NewsDetail = () => {
  const { isMobile } = useDevice();
  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  const params = useParams();
  const news_slug = params?.slug;

  const [news, setNews] = useState([])
  const [tinTuc, setTinTuc] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    pageSite: 2,
  });

  const getNewsData = async () => {
    await axiosInstance.get(`/news/getBySlug/${news_slug}`).then((res) => {
      const data = res?.data;
      console.log(data)
      setNews(data || []);
    }).catch(() => {
      message.error("Error server!");
    })
  }

  const fetchTinTuc = async () => {
    await axiosInstance
      .get(`/news/getAll`, { params: pagination })
      .then((res) => {
        const data = res?.data;
        setTinTuc(data || []);
      })
      .catch(() => message.error("Error server!"));
  };

  useEffect(() => {
    getNewsData()
    scrollToTop();
  }, [])

  useEffect(() => {
    fetchTinTuc()
  }, [pagination])

  return (
    <div className='container mx-auto mt-[90px]'>
      {/* path */}
      <div className={isMobile ? "flex items-center px-4" : "flex items-center"}>
        <div className={isMobile ? "h-10 w-10 mb-[26px] text-[#D9D9D9] hover:text-[#FA4613] mr-2 transition ease-in duration-150" : "h-8 w-8 text-[#D9D9D9] hover:text-[#FA4613] mr-2 transition ease-in duration-150"}>
          <img src={IconHome} ></img>
        </div>
        <p className="text-base">
          <Link to={'/'}>
            <span className='text-[#42495B] hover:text-[#FA4613] mr-2 transition ease-in duration-150'>Trang chủ /</span>
          </Link>
          <Link to={'/news'}>
            <span className='text-[#42495B] hover:text-[#FA4613] mr-2 transition ease-in duration-150'>Tin tức /</span>
          </Link>
          <span className='text-[#FA4613] pl-2 font-semibold'>{news.news_title}</span>
        </p>
      </div>

      {/* tin tức */}
      <Row className='mt-5'>
        {/* left */}
        <Col xs={24} xl={16} className='text-left'>
          <h1 className={isMobile ? "text-2xl font-bold text-font-montserrat text-[#42495B] px-4" : "text-2xl font-bold text-font-montserrat text-[#42495B] mt-3"}>{news.news_title}</h1>

          <div className={isMobile ? "flex justify-start items-center mt-3 px-4" : "flex justify-start items-center mt-3"}>
            <img src={IconPerson} alt="icon" width={isMobile ? "20px" : "20px"} height={20}></img>
            <p className='text-xl text-[#42495B] ml-3'>{news.username}</p>
            <p className='text-xl text-[#42495B] ml-3'>{moment(news.create_at).format('DD/MM/YYYY')}</p>
          </div>

          <h2 className={isMobile ? "text-xl font-bold text-font-montserrat text-black mt-3 px-4" : "text-xl font-bold text-font-montserrat text-black mt-3"}>{news.news_title}</h2>

          <img src={news.news_image} className={isMobile ? "w-full h-[300px] mt-3" : "w-full h-[695px] mt-3"}></img>

          <p className={isMobile ? 'text-xl text-font-montserrat text-black mt-3 px-4' : 'text-xl text-font-montserrat text-black mt-3'}>{parse(String(news?.news_description))}</p>

          {/* chủ đề */}
          {isMobile ? <>
            <div className='my-3 flex justify-start items-center px-4'>
              <p className='text-lg	 text-font-montserrat text-black'>Chủ đề: </p>
              <Link href="" className='flex items-center text-lg text-font-montserrat  border rounded-xl px-3 py-1 ml-3 hover:border-[#FA4613] hover:text-[#FA4613] '>
                <img src={IconTag} className='h-5 w-5'></img>
                <p className='text-lg ml-2 text-black'>Công Nghệ</p>
              </Link>
              <Link href="" className=' ml-3 flex items-center text-lg text-font-montserrat  border rounded-xl px-3 py-1 hover:border-[#FA4613] hover:text-[#FA4613] '>
                <img src={IconTag} className='h-5 w-5'></img>

                <p className='text-lg ml-2 text-black'>EPS*</p>
              </Link>
              
            </div>
            <div className='my-3 flex justify-start items-center px-4'>
              <Link href="" className='flex items-center text-lg text-font-montserrat border rounded-xl px-3 py-1 hover:border-[#FA4613] hover:text-[#FA4613] '>
                <img src={IconTag} className='h-5 w-5'></img>

                <p className='text-lg ml-2  text-black'>Bất động sản</p>
              </Link>
              <Link href="" className='flex items-center text-lg text-font-montserrat  border rounded-xl px-3 py-1 ml-3 hover:border-[#FA4613] hover:text-[#FA4613] '>
                <img src={IconTag} className='h-5 w-5'></img>

                <p className='text-lg ml-2 text-black'>Chứng khoán</p>
              </Link>
            </div>
          </> : <div className='my-3 flex justify-start items-center'>
            <p className='text-lg	 text-font-montserrat text-black'>Chủ đề: </p>
            <Link href="" className='flex items-center text-lg  border rounded-xl px-3 py-1 ml-3 hover:border-[#FA4613] '>
              <img src={IconTag} className='h-5 w-5'></img>
              <p className='text-lg	 ml-2 text-black  hover:text-[#FA4613] text-font-montserrat'>Công Nghệ</p>
            </Link>
            <Link href="" className='flex items-center text-lg  border rounded-xl px-3 py-1 ml-3 hover:border-[#FA4613] '>
              <img src={IconTag} className='h-5 w-5'></img>
              <p className='text-lg	 ml-2 text-black  hover:text-[#FA4613] text-font-montserrat'>Bất động sản</p>
            </Link>
            <Link href="" className='flex items-center text-lg  border rounded-xl px-3 py-1 ml-3 hover:border-[#FA4613] '>
              <img src={IconTag} className='h-5 w-5'></img>
              <p className='text-lg	 ml-2 text-black  hover:text-[#FA4613] text-font-montserrat'>EPS*</p>
            </Link>
            <Link href="" className='flex items-center text-lg  border rounded-xl px-3 py-1 ml-3 hover:border-[#FA4613] '>
              <img src={IconTag} className='h-5 w-5'></img>
              <p className='text-lg	 ml-2 text-black  hover:text-[#FA4613] text-font-montserrat'>Chứng khoán</p>
            </Link>
            {/*  */}
          </div>}

          <div className="mt-[25px] flex">
            <Link
              href=""
              target="_blank"
              rel="noreferrer"
              className="relative w-6 h-6 p-4 mr-2"
            >
              <img
                src={LogoFb}
                alt="Facebook Logo"
                layout="fill"
                objectFit="cover"
              />
            </Link>

            <Link
              href=""
              target="_blank"
              rel="noreferrer"
              className="relative w-6 h-6 p-4 mr-2"
            >
              <img
                src={LogoX}
                alt="Linkedin Logo"
                layout="fill"
                objectFit="cover"
              />
            </Link>
            <Link
              href=""
              target="_blank"
              rel="noreferrer"
              className="relative w-6 h-6 p-4 mr-2"
            >
              <img
                src={LogoInsta}
                alt="Youtube Logo"
                layout="fill"
                objectFit="cover"
              />
            </Link>
            <Link
              href=""
              target="_blank"
              rel="noreferrer"
              className="relative w-6 h-6 p-4 mr-2"
            >
              <img
                src={LogoIn}
                alt="Youtube Logo"
                layout="fill"
                objectFit="cover"
              />
            </Link>
          </div>
        </Col>


        {/* right */}
        <Col xs={24} xl={8} className=''>

          <div className={isMobile ? "" : "ml-4 mt-[92px]"}>
            <h1 className={isMobile ? "text-center text-2xl font-bold text-font-montserrat text-[#FA4613] mt-3" : "text-2xl font-bold text-font-montserrat text-[#FA4613] mt-3"}>CÓ THỂ BẠN QUAN TÂM</h1>
            <div className={isMobile ? "bg-[#FA4613] h-[1px] mb-5 w-[100%] mt-3" : "bg-[#FA4613] h-[1px] mb-5 w-[80%] mt-3"}></div>

            <div>
              {tinTuc?.data?.slice(2, 6).map((item, index) => (
                <div key={index} className={isMobile ? "mx-[30px]" : undefined}>
                  <a href={"/tin-tuc/" + item?.news_slug} className='grid grid-cols-[30%,70%] gap-2 mb-5'>
                    <div className=''>
                      <img src={item?.news_image} alt="movie" className={isMobile ? "transition-opacity duration-300 h-[110px]" : "object-cover w-full rounded-t-lg h-[130px]   md:rounded-none md:rounded-s-lg"} />
                    </div>
                    <div className=''>
                      <p className={isMobile ? "text-[#42495B] font-bold text-sm line-clamp-2" : "text-[#42495B] font-bold text-lg line-clamp-2"}>{item?.news_title}</p>
                      <p className={isMobile ? "text-[#42495B] text-sm line-clamp-3 mt-2" : "text-[#42495B] text-base mt-1 line-clamp-3"}>{parse(item?.news_description)}</p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>

      {/* tin tức nổi bật*/}
      <div>
        <h1 className={isMobile ? "text-center text-3xl font-bold text-[#42495B] mb-3 mt-5" : "text-center text-3xl font-bold text-[#42495B] mb-3 mt-8"}>Tin tức và tuyển dụng</h1>
      </div>
      <div className="">
        <AnimationLine />
      </div>

      <Row className={isMobile ? 'mt-5 mb-10 px-[24px]' : 'mt-5 mb-10'}>
        {tinTuc?.data?.slice(0, 6).map((item, index) => (
          <Col xs={24} xl={8} className={isMobile ? "mb-5" : "mb-5"}>
            <div className='flex justify-center'>
              <a href={"/tin-tuc/" + item?.news_slug}>
                <div className="max-w-sm rounded overflow-hidden shadow-lg text-[#42495B] justify-center">
                  <img className="w-full h-[200px]" src={item?.news_image} alt="Sunset in the mountains" />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2 line-clamp-2">{item?.news_title}</div>
                    <p className=" text-base line-clamp-2">
                      {parse(item?.news_description)}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default NewsDetail