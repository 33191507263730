import { Dropdown, Image, Layout, Menu } from "antd";
import React, { useEffect } from "react";
import {
  MessageOutlined,
  UserOutlined,
  VideoCameraOutlined,
  AppstoreOutlined,
  TransactionOutlined,
  LogoutOutlined,
  BookOutlined,
  UserAddOutlined,
  SettingOutlined,
  TeamOutlined,
  TruckOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
const { Sider } = Layout;


export default function CustomeSider() {

  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['admin']);

  const menuItem = [
    {
      key: "user",
      icon: <UserOutlined />,
      label: <Link to={"/admin/users"}>Quản lí người dùng</Link>,
    },
    // {
    //   key: "news",
    //   icon: <BookOutlined />,
    //   label: <Link to={"/admin/news"}>Quản lí tin tức</Link>,
    // },
    {
      label: 'Quản lí bài viết',
      key: 'SubMenu',
      icon: <SettingOutlined />,
      children: [
        {
          key: "service",
          icon: <TruckOutlined/>,
          label: <Link to={"/admin/service"}>Quản lí dịch vụ</Link>,
        },
        {
          key: "news",
          icon: <BookOutlined />,
          label: <Link to={"/admin/news"}>Quản lí tin tức</Link>,
        },
        {
          key: "about_us",
          icon: <TeamOutlined />,
          label: <Link to={"/admin/about_us"}>Quản lí về chúng tôi</Link>,
        },
      ],
    },
    {
      key: "career",
      icon: <UserAddOutlined />,
      label: <Link to={"/admin/career"}>Quản lí tuyển dụng</Link>,
    }
  ];

  useEffect(() => {
    console.log(cookies,'cookies')
      if(!cookies?.token){
        
        navigate("/loginAdmin");
      }
  }, [cookies]);
  
  return (
    <div className="!bg-white">
      <p className="text-center font-bold text-xl py-2 my-2 bg-orange-500 text-white">HOMICEN GROUP</p>
      <Sider width={220} className="!w-full !bg-white">
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={menuItem}
        />
      </Sider>
    </div>
  );
}
