import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDevice } from "../../hook/useDevice";
import { Link, useNavigate } from "react-router-dom";

import Arrow from "../Image/images/langues.svg";
import Menu from "../Image/images/menuBar.svg";
import Logo from "../Image/images/logo_hoicen_new-removebgs.svg";
import IconX from "../Image/images/iconX.svg";
const routes = [
  {
    name: "Trang chủ",
    pathNames: ["/"],
    path: "/",
  },
  {
    name: "Dịch vụ",
    pathNames: ["/service", "/dich-vu"],
    path: "service",
  },
  {
    name: "Tin tức",
    pathNames: ["/news", "/du-an"],
    path: "news",
  },
  {
    name: "Về chúng tôi",
    pathNames: ["/aboutUs"],
    path: "/about-us",
  },
  {
    name: "Tuyển dụng",
    pathNames: ["/careers", "/tuyen-dung"],
    path: "/careers",
  },
  {
    name: "Liên hệ",
    pathNames: ["/contact", "/lien-he"],
    path: "contact",
  },
  {
    name: "EPS",
    pathNames: ["/careers", "/tuyen-dung"],
    path: "",
  },
  // {
  //   name: "Đăng nhập",
  //   pathNames: ["/careers", "/tuyen-dung"],
  //   path: "/loginAdmin",
  // },
  {
    name: "",
    pathNames: [""],
    path: "/pages/bookProject",
  },
];

export default function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const { isMobile,isTablet } = useDevice();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [menuIcon, setMenuIcon] = useState(Menu);
  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setMenuIcon(isMobileMenuOpen ? Menu : IconX);
  };

const handleMobileMenuItemClick = (path) => {
  navigate(path);
  setIsMobileMenuOpen(false);
  setMenuIcon(Menu);
};;




  return (
    <>
      <div className="fixed left-0 right-0 top-0 z-[1000] bg-navy-5 font-montserrat transition-all duration-500 py-0 bg-white shadow-lg">
        <div className="container mx-auto flex h-14 items-center justify-between md:h-[70px] lg:justify-between sticky px-3 lg:px-0  ">
          <Link to="/" className="flex">
            <div
              className={
                isMobile
                  ? "relative h-[34px] w-[36px] md:h-[50px] md:w-[50px]"
                  : "relative h-[38px] w-[82px] md:h-[50px] md:w-[50px]"
              }
            >
              <img src={Logo} alt="Vercel Logo" layout="fill" priority />
            </div>
            <div className="text-black font-sans font-bold md:text-[18px] text-[12px] pl-[6px] pt-[10px]">
              HOMICEN GROUP
            </div>
          </Link>
          <div className="">
            {" "}
            {isTablet && (
              <div className="flex justify-start items-center h-[40px] w-10">
                <button
                  className="lg:hidden mt-auto mb-auto focus:outline-none"
                  onClick={handleMobileMenuToggle}
                >
                  {/* <img src={Menu} alt="Menu" /> */}
                  <img
                      className=""
                      src={menuIcon}
                      alt="Picture of the author"
                      width={30}
                      height={30}
                    />
                </button>
              </div>
            )}
          </div>
          <ul
            className={`relative hidden items-start space-x-0 space-y-10 lg:flex lg:items-center lg:space-x-[2.25rem] lg:space-y-0 2xl:space-x-8 3xl:space-x-[60px]`}
          >
            {routes.map((x) => (
              <li
                onClick={() => {
                  navigate(x.path);
                  // setIsOpen(false);
                }}
                key={x.path}
                className={`group relative cursor-pointer font-sans text-lg leading-[24px] tracking-wider transition duration-300 lg:text-base lg:leading-5 xl:text-[20px] xl:leading-[22px]`}
              >
                {t(x.name)}
                <span className="block h-1 max-w-0 bg-[#FA4613] transition-all duration-700 group-hover:max-w-full"></span>
              </li>
            ))}
          </ul>

          {/* <div className={`relative lg:flex hidden`}>
            <div className="">
              <div>
                <img src={Arrow} alt="verticalLine" />
              </div>
            </div>
          </div> */}
        </div>

        {isTablet && isMobileMenuOpen && (
          <div className="lg:hidden">
            <ul className="bg-white py-2 pl-3">
              {routes.map((x) => (
                <li
                  key={x.path}
                  onClick={() => handleMobileMenuItemClick(x.path)}
                  className={`pb-6 font-bold group relative cursor-pointer font-sans text-lg leading-[24px] tracking-wider transition duration-300 lg:text-base lg:leading-5 xl:text-[20px] xl:leading-[22px] pt-3`}
                >
                  {t(x.name)}
                <span className="block h-1 max-w-0 bg-[#FA4613] transition-all duration-700 group-hover:max-w-[15%]"></span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
