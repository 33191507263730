// import AnimationCircle from "@/component/AnimationCircle";
import Map from "../../component/ui/Map";
import WhatMakeUsDifferent from "../../component/ui/WhatMakeUsDifferent";
// import { useDevice } from "@/app/hooks/useDevice";
import { useDevice } from "../../hook/useDevice";
// import image1 from "@/public/images/1.png";
import image1 from "../../component/Image/images/1.png";
import banner from "../../component/Image/images/Banner-service.png";
import { Col, Row, message } from "antd/lib";
// import verticalLine from "/public/images/verticalLine.svg";
// import AnimationDot from "@/app/components/common/AnimationDot";
import AnimationCircle from "../../component/AnimationCircle/index";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AnimationLine from "../../component/animationLine";
import { useState, React, useEffect } from "react";
import axiosInstance from "../../until/axios";
import { Link } from "react-router-dom";
import { is } from "@babel/types";
const dataList = [
  {
    name: "Tania Andrew",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    job: "Software Engineer",
    describe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    name: "John Doe",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    job: "Frontend Developer",
    describe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    name: "Jane Doe",
    avatar: "https://randomuser.me/api/portraits/women/2.jpg",
    job: "Backend Developer",
    describe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    name: "Michael Smith",
    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
    job: "Full Stack Developer",
    describe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    name: "Emily Johnson",
    avatar: "https://randomuser.me/api/portraits/women/4.jpg",
    job: "UI/UX Designer",
    describe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const AboutUs = () => {
  const { isMobile } = useDevice();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    arrows: true,
  };

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    pageSite: 3,
  });

  const fetchCareer = async () => {
    await axiosInstance
      .get(`/news/getAll`, { params: pagination })
      .then((res) => {
        const data = res?.data;
        setData(data || []);
      })
      .catch(() => message.error("Error server!"));
  };

  useEffect(() => {
    fetchCareer();
  }, [pagination]);
  console.log(data, "datadatadata");

  return (
    <div className="mt-[60px]">
      <div
        className="h-[200px] pl-[10%] pr-[10%]"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <p className="pt-[50px] text-2xl font-bold  text-white">VỀ CHÚNG TÔI</p>
        <div className="mt-5 flex">
          <p className="text-xl text-white">Trang chủ /</p>
          <p className="pl-2 text-xl text-[#FA4613]">Về chúng tôi</p>
        </div>
      </div>
      <div className="pl-[10%] pr-[10%] pt-10">
        <div className="flex flex-col  lg:flex-row">
          <div className="flex w-[100%] flex-col items-center lg:w-[50%] ">
            <p className="lg:text-[1.875rem] font-bold text-[#42495B] text-[24px]">
              Câu chuyện thương hiệu
            </p>
            <div className="py-10 pt-10 text-base font-semibold text-[#42495B]">
              <p>
                {" "}
                Từ khát khao đưa trí tuệ Việt phục vụ mọi thứ mà khách hàng cần
                về công nghệ thông tin, trong gần 20 năm vừa qua, ETC đang vươn
                mình với những bước đi đầy táo bạo để trở thành nhà cung cấp hệ
                sinh thái công nghệ số dẫn đầu, đáp ứng nhu cầu không giới hạn
                khách hàng. của
              </p>
            </div>
            <button className="h-[50px] w-[150px] transform rounded-[10px] bg-[#FA4613] px-[18px] py-[8px] font-montserrat text-[16px] font-semibold text-white transition duration-300 hover:scale-105 hover:bg-red-600">
              Xem thêm
            </button>
          </div>
          <div className="pl-0 pt-5 lg:pl-10 lg:pt-0">
            <div className="">
              {" "}
              <div className="hidden lg:contents">
                <iframe
                  width="600"
                  height="400"
                  src="https://www.youtube.com/embed/LwNaTEDzyOo?si=6lEuAcd0_1831lYQ&autoplay=1"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="contents lg:hidden">
                <iframe
                  width="100%"
                  height="300px"
                  src="https://www.youtube.com/embed/LwNaTEDzyOo?si=6lEuAcd0_1831lYQ&autoplay=1"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------ */}
      <div className="">
        <p className="py-5 text-center text-3xl font-bold text-[#42495B]">
          Lịch sử công ty
        </p>
        <AnimationLine />

        {/* ............... */}
        <div>
          <Map />
        </div>
        {/* ............ */}
        <WhatMakeUsDifferent />
        <div>
          <p className="py-5 text-center text-[24px] font-bold text-[#42495B] lg:text-[1.875rem]">
            Thành tích đạt được
          </p>
          <AnimationLine />
        </div>
        <div className="flex flex-col items-center px-3 pt-10 lg:px-0">
          <div className="flex items-center pb-5 ">
            <div
              className={
                isMobile
                  ? "flex-none  h-[10px] rounded-full mb-[24px]"
                  : "flex-none  h-[10px] rounded-full"
              }
            >
              <AnimationCircle />
            </div>
            <div className="pl-3 text-[16px] font-medium text-[#42495B] md:text-[18px] lg:text-[22px]">
              2022: Giải thưởng “Cyber Security Partner of the year” của IBM{" "}
            </div>
          </div>
          <div className="flex items-center pb-5 ">
            <div
              className={
                isMobile
                  ? "flex-none  h-[10px] rounded-full mb-[24px]"
                  : "flex-none  h-[10px] rounded-full"
              }
            >
              <AnimationCircle />
            </div>
            <div className="pl-3 text-[16px] font-medium text-[#42495B] md:text-[18px] lg:text-[22px]">
              2022: Giải thưởng “Cyber Security Partner of the year” của IBM{" "}
            </div>
          </div>
          <div className="flex items-center pb-5 ">
            <div
              className={
                isMobile
                  ? "flex-none  h-[10px] rounded-full mb-[24px]"
                  : "flex-none  h-[10px] rounded-full"
              }
            >
              <AnimationCircle />
            </div>
            <div className="pl-3 text-[16px] font-medium text-[#42495B] md:text-[18px] lg:text-[22px]">
              2022: Giải thưởng “Cyber Security Partner of the year” của IBM{" "}
            </div>
          </div>
          <div className="flex items-center pb-5 ">
            <div
              className={
                isMobile
                  ? "flex-none  h-[10px] rounded-full mb-[24px]"
                  : "flex-none  h-[10px] rounded-full"
              }
            >
              <AnimationCircle />
            </div>
            <div className="pl-3 text-[16px] font-medium text-[#42495B] md:text-[18px] lg:text-[22px]">
              2022: Giải thưởng “Cyber Security Partner of the year” của IBM{" "}
            </div>
          </div>
        </div>

        {/* -------------- */}
        <div className="my-5">
          <p className="text-center text-[24px] font-bold text-[#42495B] lg:text-[2.25rem] mb-5">
            Tôi là homicener
          </p>
          <AnimationLine />
        </div>

        <div className={isMobile ? "py-5 pl-[10%] pr-[10%]" : "py-5"}>
          <Slider {...settings}>
            {dataList.map((data, index) => {
              return (
                <div key={index}>
                  <div
                    className={
                      isMobile
                        ? "flex justify-center w-auto h-[200px] mx-auto"
                        : "flex justify-center w-[400px] h-[400px] mx-auto"
                    }
                  >
                    <img
                      className="rounded-full"
                      src={data.avatar}
                      width={isMobile ? 200 : 400}
                      height={isMobile ? 200 : 400}
                      alt="Picture of the author"
                    />
                  </div>
                  <div className="flex justify-center">
                    <div className="pt-10 w-[500px]">
                      <p className="text-center font-bold text-xl text-black">
                        CEO
                      </p>
                      <p className="text-center font-bold text-xl text-black">
                        {data.name}
                      </p>
                      <p className="text-center font-medium text-lg text-[#42495B] italic pt-2">
                        {data.describe}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        {/* -------------- */}
        <div>
            <p className="py-5 text-center text-[24px] font-bold text-[#42495B] lg:text-[1.875rem]">
              Về con người
            </p>
            <AnimationLine />
            
          </div>
        <div className={isMobile ? "pb-5 pl-[10%] pr-[10%]" :"py-5 pl-[10%] pr-[10%]"}>
          
          
          <div className="flex justify-center pt-5">
            <div className=" text-[18px] font-semibold text-[#42495B] lg:text-[22px] text-center">
              Chúng tôi luôn nỗ lực hết mình cho môi trường làm việc vui vẻ,
              quan tâm, để CBNV luôn cảm thấy hạnh phúc khi làm việc.
            </div>
          </div>
          <Row className="py-5">
            {data.data?.map((_, index) => (
              <>
                {" "}
                { (
                  <>
                  <Col xs={24} xl={8} className={isMobile ? "py-2" : "py-5"}>
                    <Link to={"/tin-tuc/" + _?.news_slug}>
                      <div className={isMobile ? "flex justify-center my-2 h-[170px] w-[300px] mx-auto pl-0" : "flex justify-center my-2 h-[300px] w-[auto] mx-auto pl-2"}>
                        <img
                          className="h-full w-full"
                          src={
                            _?.news_image
                          }
                          alt="Picture of the author"
                        />
                      </div>
                      <div
                        className={
                          isMobile ? "pt-1 text-black" : " px-[40px] pt-1 text-black"
                        }
                      >
                        <p className="font-semibold text-xl line-clamp-3">
                          {_?.news_title}
                        </p>
                      </div>
                    </Link>
                  </Col>
                </>
                )}
              </>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
