import {
    Form,
    Row,
    Input,
    Space,
    Button,
    message,
    InputNumber,
    Select,
    Modal,
  } from "antd";
  import { useEffect, useState } from "react";
  import { Link, useNavigate } from "react-router-dom";
  import axios from "axios";
  import { ExclamationCircleOutlined } from "@ant-design/icons";
  import CustomUpload from "../customUpload";
import axiosInstance from "../../until/axios";
  
  export default function UserForm({
    id = "",
    onSubmit = () => {},
  }) {
    const navigate = useNavigate();
    const [form] = Form.useForm();
  
    const deleteUser = async () => {
      await axiosInstance
        .delete(`/user/delete/${id}`)
        .then(() => message.success("Xoá sản thành công"))
    };
  
    const removeUser = async () => {
      try {
        await deleteUser();
        return navigate("/admin/users");
      } catch (err) {
        console.log(err.message);
      }
    };
  
    const confirmDeleteBusiness = () => {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: "Bạn có chắc chắn xoá danh mục con này?",
        okText: "Xác nhận",
        cancelText: "Huỷ",
        onOk: () => removeUser(),
      });
    };
    return (
      <div className={"p-[40px] bg-white rounded-[10px]"}>
        <div className={"!text-[#2d2e32] pb-[10px]"}>
          <Link
            to={"/admin/users"}
            className={
              "text-[18px] sm:text-[24px] md:text-[26px] xl:text-[26px] font-[500] cursor-pointer "
            }
          >
            {"Thông tin Người dùng"}
          </Link>
        </div>
  
        <Form
          layout={"vertical"}
          colon={false}
          form={form}
          onFinishFailed={(e) => console.log(e)}
          onFinish={onSubmit}
        >
          <Form.Item
            label={"Họ và tên"}
            name="fullname"
            rules={[{ required: true, message: "Vui lòng nhập!" }]}
          >
            <Input size="large" placeholder={"Nhập"} />
          </Form.Item>
          <Form.Item
            label={"Tài khoản"}
            name="username"
            rules={[{ required: true, message: "Vui lòng nhập!" }]}
          >
            <Input size="large" placeholder={"Nhập"} />
          </Form.Item>
          <Form.Item
            label={"Mật khẩu"}
            name="password"
            rules={[{ required: true, message: "Vui lòng nhập!" }]}
          >
            <Input size="large" placeholder={"Nhập"} />
          </Form.Item>
          <Form.Item
            label={"Số điện thoại"}
            name="phone"
            rules={[{ required: true, message: "Vui lòng nhập!" }]}
          >
            <Input size="large" placeholder={"Nhập"} />
          </Form.Item>
  
          <Row gutter={40} className={"my-[40px] pl-[20px]"}>
            <Space align="center">
              <Button type={"primary"} htmlType={"submit"}>
                {id ? "Cập nhập" : "Tạo"}
              </Button>
              {id && (
                <Button type={"primary"} danger onClick={confirmDeleteBusiness}>
                  Xoá
                </Button>
              )}
            </Space>
          </Row>
        </Form>
      </div>
    );
  }
  