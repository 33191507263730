import React from 'react';
// import 'swiper/swiper.min.css';
// import 'swiper/css/navigation';
import styles from './CombinedSVG.module.css'; // Import CSS module
// import styles from '.';
// import './style.css';
// import './styles.css';
const Banner = () => {
  const CombinedSVG = () => {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1200 510"
        xmlSpace="preserve"
      >
        <defs>
          <filter id="f1" x="0" y="0">
            <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
          </filter>
          <linearGradient
            id="GRAD_OVERLAY"
            gradientUnits="userSpaceOnUse"
            x1="100%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop
              offset="0"
              style={{ stopColor: '#000000', stopOpacity: 0.4 }}
            />
            <stop offset="1" style={{ stopColor: '#000000', stopOpacity: 0 }} />
          </linearGradient>
          <linearGradient id="GRAD_1">
            <stop
              offset="0"
              style={{ stopColor: '#FFFFFF', stopOpacity: 0.3 }}
            />
            <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
          </linearGradient>
          <linearGradient id="GRAD_2" x1="0" y1="1" y2="0" x2="1">
            <stop offset="0" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
            <stop
              offset="1"
              style={{ stopColor: '#FFFFFF', stopOpacity: 0.2 }}
            />
          </linearGradient>
          <linearGradient id="GRAD_3">
            <stop offset="0" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
            <stop
              offset="1"
              style={{ stopColor: '#FFFFFF', stopOpacity: 0.2 }}
            />
          </linearGradient>
          <linearGradient id="GRAD_4" y2="1" x2="1">
            <stop offset="0" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
            <stop
              offset="1"
              style={{ stopColor: '#FFFFFF', stopOpacity: 0.2 }}
            />
          </linearGradient>
        </defs>
        <g id="background">
          <rect
            y="-1"
            className={`${styles.background}`}
            width="100%"
            height="800"
          />
          <rect
            y="0"
            className={`${styles.background}`}
            width="100%"
            height="800"
          />
        </g>
        <g id="graphic">
          {/* <polygon
            className="st1"
            points="848.875,311 310.808,311 579.841,-25.535"
          /> */}
          <polygon
            className={`${styles.st2}`}
            points="0,-1 0,311 42.081,311 293.299,-1"
          />
          <polygon
            className={`${styles.st3}`}
            points="310.807,311 0,311 -0.346,-67.654"
          />
        </g>
        <g id="line">
          <polyline
            className={`${styles.st4}`}
            points="1054.599,310.25 832.676,30.608 610.333,310.25 761.278,310.25 611.459,121.124 461.785,310.25"
          />
          <polyline
            className={`${styles.st5}`}
            points="1054.599,310.25 832.676,30.608 610.333,310.25 761.278,310.25 611.459,121.124 461.785,310.25"
          />
          <polyline
            className={`${styles.st6}`}
            points="1054.599,310.25 832.676,30.608 610.333,310.25 761.278,310.25 611.459,121.124 461.785,310.25"
          />
        </g>
        {/* <!-- Đoạn văn bản nằm trên bề mặt --> */}
        <text
          x="50%"
          y="40%"
          dominant-baseline="middle"
          text-anchor="middle"
          fill="white"
          font-size="64"
          font-family="Arial"
        >
          HOMICEN GROUP
        </text>
        <text
          x="50%"
          y="55%"
          dominant-baseline="middle"
          text-anchor="middle"
          fill="white"
          font-size="24"
          font-family="Arial"
        >
          Nhà cung cấp giải pháp công nghệ đa lĩnh vực, đa dịch vụ
        </text>
        {/* <rect
          x="40%"
          y="65%"
          width="200"
          height="60"
          rx="10"
          ry="10"
          fill="#FA4613"
        /> */}
        {/* <text
          x="48%"
          y="71%"
          dominant-baseline="middle"
          text-anchor="middle"
          fill="white"
          font-size="24"
          font-family="Arial"
        >
          Xem thêm
        </text> */}
      </svg>
    );
  };

  // -------------

  // const CombinedMobileSVG: React.FC = () => {
    const CombinedMobileSVG = () => {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1200 810"
        xmlSpace="preserve"
      >
        <defs>
          <filter id="f1" x="0" y="0">
            <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
          </filter>
          <linearGradient
            id="GRAD_OVERLAY"
            gradientUnits="userSpaceOnUse"
            x1="100%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop
              offset="0"
              style={{ stopColor: '#000000', stopOpacity: 0.4 }}
            />
            <stop offset="1" style={{ stopColor: '#000000', stopOpacity: 0 }} />
          </linearGradient>
          <linearGradient id="GRAD_1">
            <stop
              offset="0"
              style={{ stopColor: '#FFFFFF', stopOpacity: 0.3 }}
            />
            <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
          </linearGradient>
          <linearGradient id="GRAD_2" x1="0" y1="1" y2="0" x2="1">
            <stop offset="0" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
            <stop
              offset="1"
              style={{ stopColor: '#FFFFFF', stopOpacity: 0.2 }}
            />
          </linearGradient>
          <linearGradient id="GRAD_3">
            <stop offset="0" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
            <stop
              offset="1"
              style={{ stopColor: '#FFFFFF', stopOpacity: 0.2 }}
            />
          </linearGradient>
          <linearGradient id="GRAD_4" y2="1" x2="1">
            <stop offset="0" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
            <stop
              offset="1"
              style={{ stopColor: '#FFFFFF', stopOpacity: 0.2 }}
            />
          </linearGradient>
        </defs>
        <g id="background">
          <rect
            y="-1"
            className={`${styles.background}`}
            width="100%"
            height="800"
          />
          <rect
            y="0"
            className={`${styles.background}`}
            width="100%"
            height="800"
          />
        </g>
        <g id="graphic">
          {/* <polygon
            className="st1"
            points="848.875,311 310.808,311 579.841,-25.535"
          /> */}
          <polygon
            className={`${styles.st2}`}
            points="0,-1 0,311 42.081,311 293.299,-1"
          />
          <polygon
            className={`${styles.st3}`}
            points="310.807,311 0,311 -0.346,-67.654"
          />
        </g>
        <g id="line">
          <polyline
            className={`${styles.st4}`}
            points="1054.599,310.25 832.676,30.608 610.333,310.25 761.278,310.25 611.459,121.124 461.785,310.25"
          />
          <polyline
            className={`${styles.st5}`}
            points="1054.599,310.25 832.676,30.608 610.333,310.25 761.278,310.25 611.459,121.124 461.785,310.25"
          />
          <polyline
            className={`${styles.st6}`}
            points="1054.599,310.25 832.676,30.608 610.333,310.25 761.278,310.25 611.459,121.124 461.785,310.25"
          />
        </g>
        {/* <!-- Đoạn văn bản nằm trên bề mặt --> */}
        <text
          x="50%"
          y="50%"
          dominant-baseline="middle"
          text-anchor="middle"
          fill="white"
          font-size="64"
          font-family="Arial"
        >
          HOMICEN GROUP
        </text>
        <text
          x="50%"
          y="60%"
          dominant-baseline="middle"
          text-anchor="middle"
          fill="white"
          font-size="24"
          font-family="Arial"
        >
          Nhà cung cấp giải pháp công nghệ đa lĩnh vực, đa dịch vụ
        </text>
        {/* <rect
          x="40%"
          y="70%"
          width="200"
          height="60"
          rx="10"
          ry="10"
          fill="#FA4613"
        /> */}
        {/* <text
          x="48%"
          y="74%"
          dominant-baseline="middle"
          text-anchor="middle"
          fill="white"
          font-size="24"
          font-family="Arial"
        >
          Xem thêm
        </text> */}
      </svg>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className='hidden lg:contents'>
        <CombinedSVG />
        <button
          className="h-[70px] w-[180px] transform hover:border-[2px] hover:border-white rounded-[10px] bg-[#FA4613] px-[18px] py-[8px] font-montserrat text-[18px] font-semibold text-white transition duration-300 hover:scale-105 hover:bg-red-600 "
          style={{
            position: 'absolute',
            top: '70%',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          Xem thêm
        </button>
      </div>
      <div className='contents lg:hidden'>
        <CombinedMobileSVG />
        <button
          className="h-[40px] w-[120px] transform rounded-[10px] bg-[#FA4613] hover:border-[2px] hover:border-white px-[18px] py-[8px] font-montserrat text-[12px] font-semibold text-white transition duration-300 hover:scale-105 hover:bg-red-600"
          style={{
            position: 'absolute',
            top: '70%',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          Xem thêm
        </button>
      </div>
    </div>
    // <div className="relative min-h-[200px]">
    //   <div className=" hidden lg:contents">
    //   <div className="flex flex-col items-center justify-center">
    //   <CombinedSVG />
    //   <button className="h-[50px] w-[150px] transform rounded-[10px] bg-[#FA4613] px-[18px] py-[8px] font-montserrat text-[16px] font-semibold text-white transition duration-300 hover:scale-105 hover:bg-red-600">
    //     Xem thêm
    //   </button>
    // </div>
    //   </div>
    //   <div className=" contents lg:hidden">
    //     <CombinedMobileSVG />
    //   </div>
    // </div>
  );
};

export default React.memo(Banner);
