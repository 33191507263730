import { Table, Row, Col, Button, message, Modal, Space, Pagination } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import axiosInstance from "../../../until/axios";

export default function UsersManage() {
  const [user, setUser] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const fetchUser = async () => {
    await axiosInstance.get(`/user/getAll`, {params: pagination})
    .then((res) => {
      const data = res?.data;
      setUser(data || []);
    })
    .catch(() => message.error("Error server!"));
};

  const removeUser = async (user_id) => {
    await axiosInstance
      .delete(`/user/delete/${user_id}`)
      .finally(() => {
        fetchUser();
        message.success("Xoá thành công");
      });
  };

  const confirmDelete = (user_id) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: "Bạn có chắc chắn xoá comment này?",
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: () => removeUser(user_id),
    });
  };

  useEffect(() => {
    fetchUser();
  }, [pagination]);

  const columns = [
    {
      title: <div className={"base-table-cell-label"}>ID</div>,
      key: "user_id",
      dataIndex: "user_id",
      sorter: (a, b) => a.user_id - b.user_id,
      width: 50,
      render: (_, record) => <div>{record?.user_id}</div>,
    },
    {
      title: <div className={"base-table-cell-label"}>Họ và tên</div>,
      key: "fullname",
      dataIndex: "fullname",
      width: 280,
      ...SearchProps("fullname"),
      render: (_, record) => {
        return <div>{record?.fullname}</div>;
      },
    },
    {
      title: <div className={"base-table-cell-label"}>Tài khoản</div>,
      key: "username",
      dataIndex: "username",
      width: 280,
      ...SearchProps("username"),
      render: (_, record) => {
        return <div>{record?.username}</div>;
      },
    },
    {
      title: <div className={"base-table-cell-label"}>Mật khẩu</div>,
      key: "password",
      dataIndex: "password",
      width: 280,
      render: (_, record) => {
        return <div>{record?.password}</div>;
      },
    },
    {
      title: <div className={"base-table-cell-label"}>Số điện thoại</div>,
      key: "phone",
      dataIndex: "phone",
      width: 280,
      render: (_, record) => {
        return <div>{record?.phone}</div>;
      },
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
      key: "create_at",
      dataIndex: "create_at",
      width: 280,
      sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
      render: (_, record) => {
        return (
          <div>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.create_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      key: "operation",
      dataIndex: "operation",
      width: 100,
      render: (_, record) => {
        return (
          <Space>
            <div
              className={"text-[var(--red)]"}
              onClick={() => confirmDelete(record?.user_id)}
            >
              <DeleteOutlined />
            </div>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={10} className={"mb-[8px]"}>
        <Col flex={1}>
          <div className={"text-[20px] font-medium"}>Người dùng</div>
        </Col>
        <Col>
          <Link to={"/admin/users/create"}>
            <Button type={"primary"} onClick={() => {}}>
              Tạo
            </Button>
          </Link>
        </Col>
      </Row>

      <div className="w-full h-full mt-5 pb-20 relative">
        <Table
          className={"custom-table"}
          dataSource={user?.data}
          columns={columns}
          pagination={false}
        />
        <Pagination
          className="flex justify-center absolute inset-x-0 bottom-10"
          current={pagination.page}
          total={user?.total}
          pageSize={pagination.pageSize}
          showSizeChanger
          onChange={(p, ps)=> {
            setPagination({
              page: p,
              pageSize: ps
            })
          }}
        />
      </div>
    </>
  );
}
