import { useDevice } from "../../hook/useDevice";
import { Flex, Divider, img, Row, Col, Input, Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import LogoFb from "../Image/Footer/icon_fb.png";
import LogoIn from "../Image/Footer/icon_in.png";
import LogoInsta from "../Image/Footer/icon_insta.png";
import LogoX from "../Image/Footer/icon_x.png";
import LogoFooter from "../Image/Footer/logo_hoicen_new-removebgs.svg";
import LogoGlobal from "../Image/Footer/icon_global.png";
import LogoQr from "../Image/Footer/icon_qr.png";
import LogoPerson from "../Image/Footer/icon_person.png";
import LogoLocal from "../Image/Footer/icon_local.png";
import LogoPhone from "../Image/Footer/icon_phone.png";
import LogoLetter from "../Image/Footer/icon_letter.png";
import { Link } from "react-router-dom";

const Footer = React.memo(() => {
  const { isMobile } = useDevice();
  const { t } = useTranslation(["translation"]);
  return (
    <footer className="bg-[#ECECEC]">
      <div className="container mx-auto">
        <div className={isMobile ? "container px-8" : "container"}>
          <Row>
            <Col xs={24} xl={6}>
              <div className="mt-5">
                {/* logo */}
                <div className="flex items-center mb-5">
                  <Link href="/">
                    <div
                      className={
                        isMobile
                          ? "relative h-[40px] w-[40px] mr-2"
                          : "relative h-[50px] w-[50px] mr-2"
                      }
                    >
                      <img
                        src={LogoFooter}
                        alt="Fail"
                        layout="fill"
                        className="object-cover object-left"
                      />
                    </div>
                  </Link>
                  <p className="inline-block align-middle font-montserrat text-2xl font-bold text-[#FA4613]">
                    Homicen
                  </p>
                </div>
                {/* text */}
                <div>
                  <ul>
                    <li className="">
                      <div className="cusor-pointer mt-3 flex items-center text-[#42495B] transition duration-300 hover:scale-110 hover:text-[#FA4613]">
                        <img className="mr-2 h-5 w-5" src={LogoGlobal}></img>
                        <p className="font-sansFrancisco  text-sm font-normal leading-6 tracking-[0.03em]  ">
                          CÔNG TY TNHH HOMICEN ĐÀ NẴNG
                        </p>
                      </div>
                    </li>
                    <li className="">
                      <div className="cusor-pointer mt-3 flex items-center text-[#42495B] transition duration-300 hover:scale-110 hover:text-[#FA4613]">
                        <img className="mr-2 h-5 w-5" src={LogoQr}></img>
                        <p className="font-sansFrancisco  text-sm font-normal leading-6 tracking-[0.03em]  ">
                          Mã số thuế: 0402194852
                        </p>
                      </div>
                    </li>
                    <li className="">
                      <div className="cusor-pointer mt-3 flex items-center text-[#42495B] transition duration-300 hover:scale-110 hover:text-[#FA4613]">
                        <img className="mr-2 h-5 w-5" src={LogoPerson}></img>
                        <p className="font-sansFrancisco  text-sm font-normal leading-6 tracking-[0.03em]  ">
                          CÔNG TY TNHH HOMICEN ĐÀ NẴNG
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="text-center">
                  <Divider
                    type={isMobile ? "horizontal" : undefined}
                    className={isMobile ? "h-[1px] bg-black" : "hidden"}
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} xl={6}>
              <div className={isMobile ? "relative" : "relative mt-8"}>
                <p className="font-cabiner mt-3 text-2xl font-semibold leading-[22px] tracking-wider text-black">
                  Thông tin liên hệ
                </p>
                {/* text */}
                <div className="mt-5">
                  <ul>
                    <li className="">
                      <Link
                        href=""
                        className="cusor-pointer mt-3 flex items-center hover:scale-110"
                      >
                        <img className="mr-2 h-5 w-5" src={LogoLocal}></img>

                        <p className="font-sansFrancisco  text-sm font-normal leading-6 tracking-[0.03em] text-[#42495B]  hover:text-[#FA4613] transition duration-300">
                          05 Hà Bồng, Phường Hoà Xuân, Quận Cẩm Lệ, Đà Nẵng
                        </p>
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        href=""
                        className="cusor-pointer mt-3 flex items-center  hover:scale-110"
                      >
                        <img className="mr-2 h-5 w-5" src={LogoLetter}></img>

                        <p className="font-sansFrancisco  text-sm font-normal leading-6 tracking-[0.03em]  text-[#42495B] transition duration-300  hover:text-[#FA4613]">
                          Email: info@homicenvn.com
                        </p>
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        href=""
                        className="cusor-pointer mt-3 flex items-center hover:scale-110 "
                      >
                        <img className="mr-2 h-5 w-5" src={LogoPhone}></img>

                        <p className="font-sansFrancisco  text-sm font-normal leading-6 tracking-[0.03em]  text-[#42495B] transition duration-300 hover:text-[#FA4613] ">
                          Hotline: 0402.194.852
                        </p>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="text-center">
                  <Divider
                    type={isMobile ? "horizontal" : undefined}
                    className={isMobile ? "h-[1px] bg-black" : "hidden"}
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} xl={6}>
              <div className={isMobile ? "relative" : "relative ml-3 mt-8"}>
                <p className="font-cabiner mt-3 text-2xl font-semibold leading-[22px] tracking-wider text-black">
                  Chính sách
                </p>
                {/* text */}
                <div className="mt-5">
                  <ul>
                    <li className="cusor-pointer mt-3 flex items-center text-[#42495B] transition duration-300 hover:scale-110 hover:text-[#FA4613] ">
                      <p className="font-sansFrancisco text-sm font-normal leading-6 tracking-[0.03em]">
                        Hướng dẫn xóa tài khoản
                      </p>
                    </li>
                    <li className="cusor-pointer mt-3 flex items-center text-[#42495B] transition duration-300 hover:scale-110 hover:text-[#FA4613] ">
                      <p className="font-sansFrancisco text-sm font-normal leading-6 tracking-[0.03em]">
                        Quy định về kiểm tra hàng hóa
                      </p>
                    </li>
                    <li className="cusor-pointer mt-3 flex items-center text-[#42495B] transition duration-300 hover:scale-110 hover:text-[#FA4613] ">
                      <p className="font-sansFrancisco text-sm font-normal leading-6 tracking-[0.03em]">
                        Hình thức thanh toán
                      </p>
                    </li>
                    <li className="cusor-pointer mt-3 flex items-center text-[#42495B] transition duration-300 hover:scale-110 hover:text-[#FA4613] ">
                      <p className="font-sansFrancisco text-sm font-normal leading-6 tracking-[0.03em]">
                        Chính sách quyền riêng tư
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="text-center">
                  <Divider
                    type={isMobile ? "horizontal" : undefined}
                    className={isMobile ? "h-[1px] bg-black" : "hidden"}
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} xl={6}>
              <div className={isMobile ? "relative mb-8" : "relative my-8"}>
                <p className="font-cabiner mt-3 text-2xl font-semibold leading-[22px] tracking-wider text-black">
                  Theo dõi chúng tôi
                </p>
                {/* video */}
                <div className="mt-5">
                  <iframe
                    width="250"
                    height="130"
                    src="https://www.youtube.com/embed/h_1t3-6oWz4?si=y3wbjCfxS15ClKHV"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                {/* icon */}
                <div>
                  <div className="mt-[25px] flex">
                    <Link>
                    <img
                      className="rounded-[24px]  mr-2"
                      src={
                        LogoFb
                      }
                      alt="Picture of the author"
                      width={30}
                      height={30}
                    />
                    </Link>
                    
                    <Link>
                    <img
                      className="rounded-[24px]  mr-2"
                      src={
                        LogoInsta
                      }
                      alt="Picture of the author"
                      width={30}
                      height={30}
                    />
                    </Link>

                    <Link>
                    <img
                      className="rounded-[24px]  mr-2"
                      src={
                        LogoIn
                      }
                      alt="Picture of the author"
                      width={30}
                      height={30}
                    />
                    </Link>
                    
                    <Link>
                    <img
                      className="rounded-[24px]  mr-2"
                      src={
                        LogoX
                      }
                      alt="Picture of the author"
                      width={30}
                      height={30}
                    />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="h-[1px] bg-[#FA4613]"></div>
        <div className={isMobile ? "mb-5" : undefined}>
          <p className=" mb-1 mt-1 text-center font-sansFrancisco text-sm font-normal italic leading-6 tracking-[0.03em] text-[#42495B]">
            @ Bản quyền thuộc Homicen Group 2024
          </p>
        </div>
      </div>
    </footer>
  );
});

Footer.displayName = "Footer";

export default Footer;
