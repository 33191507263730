

import { useAnimation } from "framer-motion";
// import { useTranslation } from "next-i18next";
// import Image from "next/image";
import * as React from "react";
import { useInView } from "react-intersection-observer";
// import { Swiper, SwiperSlide } from "swiper/react";
// import bds from "/public/images/bds.png";
// import cang from "/public/images/cang.webp";
// import nangluong from "/public/images/gio.webp";
// import line from "/public/images/linhvucs.png";
// import { Autoplay, Navigation, Pagination } from "swiper";
// import Line from '@/components/common/Line';
import { useDevice } from "../../../hook/useDevice";
// import AnimationDot from "@/app/components/common/AnimationDot";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AnimationLine from "../../animationLine";
export default function AboutUs() {
  // const { t, ready } = useTranslation("about");

  const { isMobile } = useDevice();

  const dataList = [
    {
      name: "Bất động sản",
      avatar:
        "https://theme.hstatic.net/200000349909/1000718920/14/home_policy_image_1.png?v=2843",
      job: "Software Engineer",
      describe:
        "Tập đoàn khát vọng trở thành một trong những tập đoàn bất động sản hàng đầu việt nam.",
    },
    {
      name: "Năng lượng gió",
      avatar:
        "	http://res.cloudinary.com/du5oymgsm/image/upload/v1711080042/prcvx7xlgr5b7ukc0gp4.webp",
      job: "Frontend Developer",
      describe:
        "Năng lượng chính là trụ cột vững mạnh thứ hai trong hoạt động đầu tư của Tập đoàn.",
    },
    {
      name: "Vận tải biển",
      avatar:
        "http://res.cloudinary.com/du5oymgsm/image/upload/v1711080070/szsxf6qzsrqdzwzcxwpj.webp",
      job: "Backend Developer",
      describe:
        "Dịch vụ cảng biển - logistics của chúng tôi đang dần có được ưu thế cạnh tranh.",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    arrows: false,
  };

  const [ref, inView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });
  const animation = useAnimation();

  React.useEffect(() => {
    // console.log(inView)
    if (inView) {
      animation.start("visible");
    }
  }, [inView, animation]);

  return (
    <section
      className="container mx-auto lg:mt-[97px] xl:px-10 mt-[20px]"
      ref={ref}
    >
      <div className="pb-5">
        {" "}
        <div>
          <div className="flex justify-center  font-sans lg:text-[1.875rem] text-[1.5rem] font-bold text-black">
            Lĩnh vực hoạt động
          </div>
        </div>
      </div>

      <div className="pb-5">
        <AnimationLine />
      </div>
      {/* ------------------- */}

      {/* -------------- */}

      <div className="">
        <Slider {...settings}>
          {dataList.map((data, index) => {
            return (
              <div key={index}>
                <div className="flex justify-between flex-col lg:flex-row items-center ">
                  {" "}
                  <div className="md:pr-8" pr-0>
                    <div
                      className="relative xm:h-[600px] xm:w-[400px]
                    sm:h-[600px] sm:w-[400px] "
                    >
                      <img
                        className="rounded-[16px]"
                        src={data.avatar}
                        width={isMobile ? 400 : 500}
                        height={isMobile ? 600 : 600}
                        style={{ height: '600px' }}
                        alt="Picture of the author"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex  pb-8 font-sans lg:text-5xl text-3xl font-bold text-[#FA4613]">
                    {data.name}
                    </div>
                    <div className=" w-[80%] pb-8 font-sans text-[18px] font-bold text-black">
                      <div className="">
                        <div>
                        {data.describe}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="flex items-center">
                          {" "}
                          <div className="relative xm:h-[50px] xm:w-[50px] sm:h-[50px] sm:w-[50px]">
                            {/* <Image
                              className="rounded-[24px]"
                              src={line}
                              layout="fill"
                              alt=""
                            /> */}
                            <img
                              className="rounded-[24px]"
                              width={50}
                              height={50}
                              src={
                                "http://res.cloudinary.com/du5oymgsm/image/upload/v1710567194/t684z0uv3uff0blfuh7e.svg"
                              }
                              alt="Picture of the author"
                            />
                          </div>
                          <div className="lg:pb-0 pb-0 pl-6 font-sans text-[16px] font-bold text-[#FA4613] items-center">
                            Unique UI/UX Design
                          </div>
                        </div>
                        <div className="flex pt-5 items-center">
                          {" "}
                          <div className="relative xm:h-[50px] xm:w-[50px] sm:h-[50px] sm:w-[50px]">
                            {/* <Image
                              className="rounded-[24px]"
                              src={line}
                              layout="fill"
                              alt=""
                            /> */}
                             <img
                              className="rounded-[24px]"
                              width={50}
                              height={50}
                              src={
                                "http://res.cloudinary.com/du5oymgsm/image/upload/v1710567194/t684z0uv3uff0blfuh7e.svg"
                              }
                              alt="Picture of the author"
                            />
                          </div>
                          <div className="lg:pb-0 pb-0 pl-6 font-sans text-[16px] font-bold text-[#FA4613] items-center">
                            Unique UI/UX Design
                          </div>
                        </div>
                        <div className="flex pt-5 items-center">
                          {" "}
                          <div className="relative xm:h-[50px] xm:w-[50px] sm:h-[50px] sm:w-[50px]">
                            {/* <Image
                              className="rounded-[24px]"
                              src={line}
                              layout="fill"
                              alt=""
                            /> */}
                            <img
                              className="rounded-[24px]"
                              width={50}
                              height={50}
                              src={
                                "http://res.cloudinary.com/du5oymgsm/image/upload/v1710567194/t684z0uv3uff0blfuh7e.svg"
                              }
                              alt="Picture of the author"
                            />
                          </div>
                          <div className="lg:pb-0 pb-0 pl-6 font-sans text-[16px] font-bold text-[#FA4613] items-center">
                            Unique UI/UX Design
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-16">
                      <button className="h-[60px] w-[150px] transform rounded-[10px] bg-[#FA4613] px-[18px] py-[8px] font-montserrat text-[16px] font-semibold text-white transition duration-300 hover:scale-105 hover:bg-red-600">
                        Xem thêm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
}
