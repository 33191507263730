import banner from "../../component/Image/images/Banner-service.png";
import money from "../../component/Image/images/money.svg";
import { Button, Col, Row, message } from "antd/lib";
import { Link, useNavigate } from "react-router-dom";
import { useDevice } from "../../hook/useDevice";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState, React } from "react";
import axiosInstance from "../../until/axios";
import AnimationLine from "../../component/animationLine";
import dayjs from "dayjs";
const dataList = [
  {
    name: "Tania Andrew",
    avatar:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    job: "Software Engineer",
    describe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    name: "John Doe",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    job: "Frontend Developer",
    describe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    name: "Jane Doe",
    avatar: "https://randomuser.me/api/portraits/women/2.jpg",
    job: "Backend Developer",
    describe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    name: "Michael Smith",
    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
    job: "Full Stack Developer",
    describe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    name: "Emily Johnson",
    avatar: "https://randomuser.me/api/portraits/women/4.jpg",
    job: "UI/UX Designer",
    describe:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];
const Careers = () => {
  const { isMobile } = useDevice();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    arrows: true,
  };

  const [careers, setCareers] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    // pageSite:2,
  });

  const fetchCareer = async () => {
    await axiosInstance
      .get(`/career/getAll`, { params: pagination })
      .then((res) => {
        const data = res?.data;
        setCareers(data || []);
      })
      .catch(() => message.error("Error server!"));
  };

  useEffect(() => {
    fetchCareer();
  }, [pagination]);

  console.log(careers, "careers");

  return (
    <div className="mt-[60px]">
      <div
        className="h-[200px] pl-[10%] pr-[10%]"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <p className="pt-[50px] font-bold text-2xl text-white">TUYỂN DỤNG</p>
        <div className="flex mt-5">
          <p className="text-xl text-white">Trang chủ /</p>
          <p className="text-[#FA4613] pl-2 text-xl">Tuyển dụng</p>
        </div>
      </div>
      <div className="pl-[10%] pr-[10%] pt-20">
        <p className="text-[#42495B] text-center font-bold text-2xl mb-5">
          HOT JOB
        </p>
        <AnimationLine />
        <div className="pt-10 justify-center">
          {careers.data?.map((_, index) => (
            <>
              {" "}
              {index < 4 && (
                <>
                  <Row className="py-10 mb-5 text-center bg-[#F9F9F9] drop-shadow-xl hover:bg-[#f6beae] cursor-pointer transition duration-100 ">
                    <Col xs={24} xl={6}>
                      <p className="text-[#FA4613] font-bold text-lg">
                        {_?.career_title?.slice(0, 25)}...
                      </p>
                    </Col>
                    <Col xs={24} xl={6}>
                      <div
                        className={
                          isMobile ? "" : "flex justify-evenly items-center"
                        }
                      >
                        <img
                          className={isMobile ? "mx-auto" : undefined}
                          src={money}
                          alt="Picture of the author"
                          width={20}
                          height={20}
                        />
                        <p className="text-[#42495B] text-lg font-medium pl-2 ">
                          Thỏa thuận
                        </p>
                      </div>
                    </Col>
                    <Col xs={24} xl={6}>
                      <p className="text-[#42495B] text-lg font-medium">
                        {dayjs(_?.create_at).format("DD/MM/YYYY")}
                      </p>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Link to={"/tuyen-dung/" + _?.career_slug}>
                        <button
                          className="border-[#FA4613] border px-4 rounded-sm text-[#FA4613] font-bold h-[40px] hover:bg-[#FA4613] hover:text-white"
                          //   onClick={handleButtonClick}
                        >
                          Ứng tuyển ngay
                        </button>
                      </Link>
                    </Col>
                  </Row>
                </>
              )}
            </>
          ))}

          <div className="flex justify-center items-center pt-10">
            <button className="hover:border-[#FA4613] items-center text-xl font-semibold hover:bg-white border px-6 rounded-sm hover:text-[#FA4613] h-[50px] bg-[#FA4613] text-white flex justify-center">
              Xem thêm
            </button>
          </div>
        </div>
      </div>
      <div className="pl-[10%] pr-[10%] pt-20">
        <p className="text-[#42495B] text-center font-bold text-2xl mb-5">
          HOMICEN MANG TỚI CHO BẠN RẤT NHIỀU CƠ HỘI
        </p>
        <AnimationLine />
        <Row className="">
          {careers.data?.map((_, index) => (
            <>
              {" "}
              {
                <>
                  <Col xs={24} xl={8} className={isMobile ? "py-2" : "py-5"}>
                    <Link to={"/tuyen-dung/" + _?.career_slug}>
                      <div className={isMobile ? "flex justify-center my-2 h-[170px] w-[300px] mx-auto pl-0" : "flex justify-center my-2 h-[300px] w-[auto] mx-auto pl-2"}>
                        <img
                          className="h-full w-full"
                          src={_?.career_image}
                          alt="Picture of the author"
                        />
                      </div>
                      <div
                        className={
                          isMobile
                            ? "pt-1 text-black"
                            : " px-[40px] pt-1 text-black"
                        }
                      >
                        <p className="font-semibold text-xl line-clamp-3">
                          {_?.career_title}
                        </p>
                      </div>
                    </Link>
                  </Col>
                </>
              }
            </>
          ))}
        </Row>
      </div>
      <div className="pl-[10%] pr-[10%] py-20">
        <p className="text-[#42495B] text-center font-bold text-2xl mb-5">
          Tôi là homicener
        </p>
        <AnimationLine />

        <div className="pt-20">
          <Slider {...settings}>
            {dataList.map((data, index) => {
              return (
                <div key={index}>
                  <div
                    className={
                      isMobile
                        ? "flex justify-center w-auto h-[200px] mx-auto"
                        : "flex justify-center w-[400px] h-[400px] mx-auto"
                    }
                  >
                    <img
                      className="rounded-full"
                      src={data.avatar}
                      width={isMobile ? 200 : 400}
                      height={isMobile ? 200 : 400}
                      alt="Picture of the author"
                    />
                  </div>
                  <div className="flex justify-center">
                    <div className="pt-10 w-[500px]">
                      <p className="text-center font-bold text-xl text-black">
                        CEO
                      </p>
                      <p className="text-center font-bold text-xl text-black">
                        {data.name}
                      </p>
                      <p className="text-center font-medium text-lg text-[#42495B] italic pt-2">
                        {data.describe}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Careers;
