import money from "../../../component/Image/images/money.svg";
import { Button, Col, Row, message } from "antd/lib";
import { useDevice } from "../../../hook/useDevice";
import AnimationLine from "../../../component/animationLine";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useEffect, useState, React } from "react";
import axiosInstance from "../../../until/axios";
import parse from "html-react-parser";
const CareersId = () => {
  const { isMobile } = useDevice();
  const [careers, setCareers] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    // pageSite:2,
  });
  const params = useParams();
  const career_slug = params?.id;
  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  const fetchCareer = async () => {
    await axiosInstance
      .get(`/career/getAll`, { params: pagination })
      .then((res) => {
        const data = res?.data;
        setCareers(data || []);
      })
      .catch(() => message.error("Error server!"));
  };

  const [listCareers, setListCareers] = useState([]);

  function handleButtonClick() {
    window.location.reload();
  }

  const getNewsData = async () => {
    await axiosInstance
      .get(`/career/getBySlug/${career_slug}?`)
      .then((res) => {
        const data = res?.data;
        console.log(data, "wwwwwwwww");
        setListCareers(data || []);
      })
      .catch(() => {
        message.error("Error server!");
      });
  };

  useEffect(() => {
    fetchCareer();
    getNewsData();
    scrollToTop();
  }, [params]);
  return (
    <div className="mt-[60px]">
      <div
        className={
          isMobile ? "pl-[10%] pr-[10%]" : "pl-[10%] pr-[10%] py-10 flex"
        }
      >
        <p className="text-[#42495B] text-lg">Trang chủ / Tuyển dụng /</p>
        <p
          className={
            isMobile
              ? "text-[#FA4613] text-lg font-bold"
              : "text-[#FA4613] text-lg font-bold pl-2"
          }
        >
          {listCareers.career_title}
        </p>
      </div>
      <div className="pl-[10%] pr-[10%] py-10">
        <p className="text-[#42495B] text-2xl font-bold">
          {listCareers.career_title}
        </p>
        <Row className="py-5">
          <Col xs={24} xl={8}>
            <div className="flex items-center pr-5">
              <div
                className={
                  isMobile
                    ? "w-[10px] h-[10px] bg-[#FA4613] rounded-full"
                    : "w-[20px] h-[20px] bg-[#FA4613] rounded-full"
                }
              ></div>
              <p className="text-black text-lg font-bold pl-2">
                Kinh nghiệm 1 năm
              </p>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="flex items-center pr-5">
              <div
                className={
                  isMobile
                    ? "w-[10px] h-[10px] bg-[#FA4613] rounded-full"
                    : "w-[20px] h-[20px] bg-[#FA4613] rounded-full"
                }
              ></div>
              <p className="text-black text-lg font-bold pl-2">
                Mức lương lên đến $1500
              </p>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="flex items-center pr-5">
              <div
                className={
                  isMobile
                    ? "w-[10px] h-[10px] bg-[#FA4613] rounded-full"
                    : "w-[20px] h-[20px] bg-[#FA4613] rounded-full"
                }
              ></div>
              <p className="text-black text-lg font-bold pl-2">
                Ngày đăng:{dayjs(listCareers?.create_at).format("DD/MM/YYYY")}
              </p>
            </div>
          </Col>
        </Row>
        <p
          className={
            isMobile
              ? "text-xl text-font-montserrat text-black mt-3 px-4"
              : "text-xl text-font-montserrat text-black mt-3"
          }
        >
          {parse(String(listCareers?.career_description))}
        </p>
      </div>

      <div className="pl-[10%] pr-[10%] py-20">
        <p className="text-[#42495B] text-center font-bold text-2xl mb-5">
          HOT JOB
        </p>

        <AnimationLine />
        <div className="pt-10 justify-center">
          {careers.data?.map((_, index) => (
            <>
              {" "}
              {index < 4 && (
                <>
                  <Row className="py-10 mb-5 text-center bg-[#F9F9F9] drop-shadow-xl hover:bg-[#f6beae] cursor-pointer transition duration-100 ">
                    <Col xs={24} xl={6}>
                      <p className="text-[#FA4613] font-bold text-lg">
                        {_?.career_title?.slice(0, 25)}...
                      </p>
                    </Col>
                    <Col xs={24} xl={6}>
                      <div
                        className={
                          isMobile ? "" : "flex justify-evenly items-center"
                        }
                      >
                        <img
                          className={isMobile ? "mx-auto" : undefined}
                          src={money}
                          alt="Picture of the author"
                          width={20}
                          height={20}
                        />
                        <p className="text-[#42495B] text-lg font-medium pl-2 ">
                          Thỏa thuận
                        </p>
                      </div>
                    </Col>
                    <Col xs={24} xl={6}>
                      <p className="text-[#42495B] text-lg font-medium">
                        {dayjs(_?.create_at).format("DD/MM/YYYY")}
                      </p>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Link to={"/tuyen-dung/" + _?.career_slug}>
                        <button
                          className="border-[#FA4613] border px-4 rounded-sm text-[#FA4613] font-bold h-[40px] hover:bg-[#FA4613] hover:text-white"
                          //   onClick={handleButtonClick}
                        >
                          Ứng tuyển ngay
                        </button>
                      </Link>
                    </Col>
                  </Row>
                </>
              )}
            </>
          ))}

          <div className="flex justify-center items-center pt-10">
            <button className="hover:border-[#FA4613] items-center text-xl font-semibold hover:bg-white border px-6 rounded-sm hover:text-[#FA4613] h-[50px] bg-[#FA4613] text-white flex justify-center">
              Xem thêm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersId;
