import New from "../../../component/new";
import Partner from "../../../component/parner";
import Banner from "../../../component/ui/Banner/index";
import WhatMakeUsDifferent from "../../../component/ui/WhatMakeUsDifferent/WhatMakeUsDifferent";
import About from "../../../component/ui/AboutUs/index";
import FeedBack from "../../../component/feedBack";
import Technology from "../../../component/technology";
export default function Home() {
    return (
        <>
            <Banner />
            <About />
            <WhatMakeUsDifferent />
            <Technology />
            <New />
            <Partner />
            <FeedBack />
        </>
    );
}
