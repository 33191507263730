import { Form, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../../until/axios";
import ServiceForm from "../../../component/ServiceForm";

export default function ServiceDetail() {

    const [form] = Form.useForm();
    const params = useParams();
    const news_id = params?.id;
    const [initialValues, setInitialValues] = useState({});
    const navigate = useNavigate();
    
    const fetchNew = async () => {
        await axiosInstance
          .get(`/news/getById/${params?.id}`)
          .then((res) => {
            const data = res?.data;
            const values = {
              ...data,
            };
            console.log(params?.id, values);
            setInitialValues(values);
          });
      };
    
      const createNew = async (values) => {
        const merch = {...values, page: 1}
        await axiosInstance.post(`/news/create`, merch);
      };

      const updateNew = async (id, values) => {
        await axiosInstance.post(
          `/news/update/${id}`,
          values
        );
      };
      console.log(updateNew)

      const onSubmit = async (values) => {
        const submitValues = {
          ...values,
          page:1
        };

        try {
          if (news_id && news_id !== "create") {
            await updateNew(news_id, submitValues);
            console.log(news_id)
            message.success("Cập nhập thành công");
            navigate("/admin/service");
          } else {
            await createNew(submitValues);
            message.success("Tạo mới thành công");
            navigate("/admin/service");
          }
        } catch (error) {
          if(error){
            message.error("Bài viết đã tồn tại");
          }
        }
      };

      useEffect(() => {
        if (news_id && news_id !== "create") {
            fetchNew();
        }
      }, [news_id]);


      useEffect(()=>{
        if (news_id) fetchNew();
        form.resetFields();
      },[form, news_id])

    return (
        <div className="p-[40px] bg-white rounded-[10px]">
            <ServiceForm id={news_id !== "create" ? news_id : undefined}
             onSubmit={onSubmit} 
             initialValues={initialValues}/>
        </div>
    )
}