import { Button, Form, Input, Modal, Row, Space, message } from "antd";
import ReactQuill from "react-quill";
import { Link, useNavigate } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import CustomUpload from "../customUpload";
import axiosInstance from "../../until/axios";
import { useEffect } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function NewForm({
    id = "",
    initialValues = {},
    onSubmit = () => { },
}) {

    const navigate = useNavigate();
    const [form] = Form.useForm();

    const deleteNew = async () => {
        await axiosInstance.delete(`/news/delete/${id}`).then(() => message.success("Xoá bài tuyển dụng thành công"))
    }

    useEffect(() => {
        if (Object.keys(initialValues)?.length > 0) {
            form.resetFields();
        }
    }, [form, initialValues])

    const removeNew = async () => {
        try {
            await deleteNew();
            return navigate("/admin/news");
        } catch (err) {
            console.log(err.message);
        }
    };

    const confirmDeleteBusiness = () => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn xoá bài tuyển dụng này?",
            okText: "Xác nhận",
            cancelText: "Huỷ",
            onOk: () => removeNew(),
        });
    };

    return (
        <div className="p-[40px] bg-white rounded-[10px]">
            <div className="!text-[#2d2e32] pb-[10px]">
                <Link
                    to={"/admin/news"}
                    className={
                        "text-[18px] sm:text-[24px] md:text-[26px] xl:text-[26px] font-[500] cursor-pointer "
                    }
                >
                    {"Thông tin bài viết"}
                </Link>
                <Form
                    layout={"vertical"}
                    colon={false}
                    form={form}
                    initialValues={initialValues}
                    onFinishFailed={(e) => console.log(e)}
                    onFinish={onSubmit}
                >
                    <Form.Item
                        name="news_title"
                        label={"Tên bài viết"}
                        rules={[
                            {
                                required: true,
                                message: "Vui lòng nhập tên bài viết",
                            },
                        ]}
                    >
                        <Input
                            size="large" placeholder={"Nhập"}
                        />
                    </Form.Item>
                    <Form.Item
                        name="news_description"
                        label={"Mô tả"}
                        rules={[{ required: true, message: "Vui lòng nhập nội dung!" }]}
                    >
                        <ReactQuill className="h-[400px] pb-10" theme="snow" />
                    </Form.Item>
                    <Form.Item
                        name="news_image"
                        label={"Link ảnh"}
                        rules={[{ required: true, message: "Vui lòng chọn file!" }]}
                    >
                        <CustomUpload type="image" accept=".png, .jpg, .jpeg, .jfif" />
                    </Form.Item>
                    <Row gutter={40} className={"my-[40px] pl-[20px]"}>
                        <Space align="center">
                            <Button type={"primary"} htmlType={"submit"}>
                                {id ? "Cập nhật" : "Tạo"}
                            </Button>
                            {id && (
                                <Button type={"primary"} danger onClick={confirmDeleteBusiness}>
                                    Xoá
                                </Button>
                            )}
                        </Space>
                    </Row>
                </Form>
            </div>
        </div>
    )
}