import { Button, Col, Image, Modal, Pagination, Row, Space, Table, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import axiosInstance from "../../../until/axios";


export default function CareersManage() {
    
  const [careers, setCareers] = useState([]);
  const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 10,
  });


  const fetchCareer = async () =>{
    await axiosInstance.get(`/career/getAll`, {params: pagination})
      .then((res)=>{
        const data = res?.data;
        setCareers(data || []);

      }).catch(() => message.error("Error server!"));
  };

  const removeCareer = async (career_id) => {
    await axiosInstance.delete(`/career/delete/${career_id}`)
      .finally(() => {
        fetchCareer();
        message.success("Xoá thành công");
      });
  };

  const confirmDelete = (career_id) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: "Bạn có chắc chắn xoá comment này?",
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: () => removeCareer(career_id),
    });
  };

  useEffect(() => {
      fetchCareer();
  }, [pagination]);


  const columns = [
    {
        title: <div className={"base-table-cell-label"}>ID</div>,
        key: "career_id",
        dataIndex: "career_id",
        sorter: (a, b) => a.career_id - b.career_id,
        width: 50,
        render: (_, record) => <div>{record?.career_id}</div>,
      },
    {
        title: <div className={"base-table-cell-label"}>Tên bài viết</div>,
        key: "career_title",
        dataIndex: "career_title",
        ...SearchProps("career_title"),
        render: (_, record) => {
            return <div>{record?.career_title}</div>;
          },
    },
    {
        title: <div className={"base-table-cell-label"}>Mô tả bài viết</div>,
        key: "career_description",
        dataIndex: "career_description",
        render: (_, record) => {
            return <div>{record?.career_description?.slice(0, 20)}</div>;
          },
    },
    {
        title: <div className={"base-table-cell-label"}>Hình ảnh</div>,
        key: "career_image",
        dataIndex: "career_image",
        render: (_, record) => {
            return <div><Image src={record?.career_image} height={100}/></div>;
          },
    },
    {
        title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
        key: "create_at",
        dataIndex: "create_at",
        width: 280,
        sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
        render: (_, record) => {
          return (
            <div>
              <span className={"!inline-block min-w-[100px]"}>
                {dayjs(record?.create_at).format("DD/MM/YYYY")}
              </span>
            </div>
          );
        },
      },
      {
        key: "operation",
        dataIndex: "operation",
        width: 100,
        render: (_, record) => {
          return (
            <Space>
              <Link
                to={`/admin/career/${record?.career_id}`}
                className={"text-[var(--blue)]"}
              >
                <EditOutlined />
              </Link>
              <div
                className={"text-[var(--red)]"}
                onClick={() => confirmDelete(record?.career_id)}
              >
                <DeleteOutlined />
              </div>
            </Space>
          );
        },
      },
  ]
      

  return (
      <>
      <Row gutter={10} className={"mb-[8px]"}>
      <Col flex={1}>
        <div className={"text-[20px] font-medium"}>Bài viết</div>
      </Col>
      <Col>
        <Link to={'/admin/career/create'}>
          <Button type={"primary"} onClick={() => {}}>
            Tạo
          </Button>
        </Link>
      </Col>
    </Row>

    <div className="w-full h-full mt-5 pb-20 relative">
    <Table dataSource={careers?.data} columns={columns} pagination={false} />
    <Pagination
        className="flex justify-center absolute inset-x-0 bottom-10"
        current={pagination.page}
        total={careers?.total}
        pageSize={pagination.pageSize}
        showSizeChanger
        onChange={(p, ps)=> {
          setPagination({
            page: p,
            pageSize: ps
          })
        }}
      />
    </div>
    </>
  )
}