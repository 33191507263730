import { Route, Routes } from "react-router-dom";
import AdminPage from "../page/admin";
import UsersManage from "../page/admin/userManage";
import UserDetail from "../page/admin/userManage/detail";
import CareersManage from "../page/admin/careersManege";
import CareersDetails from "../page/admin/careersManege/detail";
import NewsManager from "../page/admin/newsManager";
import NewDetail from "../page/admin/newsManager/detail";
import ServiceManager from "../page/admin/serviceManager";
import ServiceDetail from "../page/admin/serviceManager/detail";
import AboutUsManager from "../page/admin/aboutUsManager";
import AboutUsDetail from "../page/admin/aboutUsManager/detail";


export default function AdminRouter () {
    return (
      <Routes>
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/users" element={<UsersManage />} />
        <Route path="/admin/users/:id" element={<UserDetail />} />
        <Route path="/admin/career" element={<CareersManage />} />
        <Route path="/admin/career/:id" element={<CareersDetails />} />
        <Route path="/admin/news" element={<NewsManager />} />
        <Route path="/admin/news/:id" element={<NewDetail />} />
        <Route path="/admin/service" element={<ServiceManager />} />
        <Route path="/admin/service/:id" element={<ServiceDetail />} />
        <Route path="/admin/about_us" element={<AboutUsManager />} />
        <Route path="/admin/about_us/:id" element={<AboutUsDetail />} />


      </Routes>
    );
  };